import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard React examples
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Soft UI Dashboard React context
import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";

// Images
import Door from "examples/Icons/Door";
import Person from "examples/Icons/Person";
import { Switch } from "@mui/material";
import SidenavIcon from "examples/Icons/Sidenav";
import SuiButton from "components/SuiButton";
import { v4 as uuidv4 } from "uuid";
import { Emergency, Me } from "components/BackendService";
import { useDispatch, useSelector } from 'react-redux'
import { changeTheme, darkThemeValue } from "slices/dakThemeSlice";
import { tokenValue } from "slices/tokenSlice";
import { meValue, setBalance } from "slices/meSlice";

function DashboardNavbar({ absolute, light, isMini }) {
  const dispatchRedux = useDispatch()
  const darkTheme = useSelector(darkThemeValue)
  const { token } = useSelector(tokenValue)
  const { balance, admin } = useSelector(meValue)
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >

      <NotificationItem
        color="secondary"
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            payment
          </Icon>
        }
        title={["", "Payment successfully completed"]}
        date="2 days"
        onClick={handleCloseMenu}
      />
    </Menu>
  );

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      style={{boxShadow: "0px 4px 10px rgba(138, 43, 226, 0.3)"}}
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SuiBox color="inherit" sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} display='flex' alignItems='center' />
          {admin &&
            <SuiButton
              sx={{ display: { md: 'none' } }} onClick={() => {
                Emergency(token, uuidv4(), 10).then(() => {
                  Me(token).then(res => {
                    dispatchRedux(setBalance(res.data.balance))
                  })
                })
              }}>
              I need money
            </SuiButton>
          }
          <IconButton
            size="large"
            color="inherit"
            sx={{ ...navbarMobileMenu, padding: 0, display: { md: 'none' } }}
            onClick={handleMiniSidenav}
          >
            <SidenavIcon size={28} />
          </IconButton>

        </SuiBox>
        {isMini ? null : (
          <SuiBox sx={(theme) => navbarRow(theme, { isMini })} display='flex' alignItems='center' justifyContent='spaceAround'>
            {admin &&
              <SuiButton
                sx={{ display: { xs: 'none', md: 'flex' }, marginRight: '15px' }} onClick={() => {
                  Emergency(token, uuidv4(), 10)
                    .then(() => {
                      Me(token)
                        .then(res => {
                          dispatchRedux(setBalance(res.data.balance))
                        })
                    })
                }}>
                I need money
              </SuiButton>
            }
            <Switch color='primary' defaultChecked checked={darkTheme.theme} onChange={() => {
              dispatchRedux(changeTheme())
            }} />
            {token && <SuiTypography fontWeight="medium" fontSize={20} mr={2} ml={2}>Balance - {balance} credits</SuiTypography>}
            <SuiBox color={light ? "white" : "inherit"}>
              <Link to={token ? '/sign-out' : '/sign-in'}>
                <IconButton sx={navbarIconButton} size="small">
                  {token ?
                    <Door size={15} color={darkTheme.theme ? 'white' : 'black'} /> :
                    <Person size={15} color={darkTheme.theme ? '#dee2e6' : 'black'} />
                  }
                  <SuiTypography
                    variant="button"
                    fontWeight="medium"
                    color={light ? "white" : "dark"}
                  >
                    {token ? 'Sign Out' : 'Sign In'}
                  </SuiTypography>
                </IconButton>
              </Link>
              {renderMenu()}
            </SuiBox>
            <IconButton
              size="large"
              color="inherit"
              sx={{ ...navbarMobileMenu, padding: 0, display: { xs: 'none', md: 'flex', xl: 'none', marginLeft: '15px' } }}
              onClick={handleMiniSidenav}
            >
              <Icon sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <SidenavIcon size={50} />
              </Icon>
            </IconButton>
          </SuiBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
