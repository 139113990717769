// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

function Monitor({ size }) {
    return ( 
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 459.343 459.343" style={{"enableBackground":"new 0 0 459.343 459.343"}} xmlSpace="preserve" width={size} height={size}>
	<g>
		<g>
			<path d="M0,6.172v323.571h172.8v71.4h-30.857v52.029H317.4v-52.029h-30.856v-71.4h172.799V6.172H0z M302.4,416.142v22.029h-0.001
				H156.943v-22.029H302.4z M187.8,401.143v-71.4h83.743v71.4H187.8z M444.343,314.743H15v-71.4h429.343V314.743z M444.343,228.342
				H15V21.172h429.343V228.342z"/>
			<path d="M119.914,61.714H67.886v138.429h52.028V61.714z M104.914,185.144H82.886V76.714h22.028V185.144z"/>
			<path d="M193.971,98.743h-52.028v101.4h52.028V98.743z M178.971,185.144h-22.028v-71.4h22.028V185.144z"/>
			<path d="M268.028,111.086H216v89.057h52.028V111.086z M253.028,185.144H231v-59.057h22.028V185.144z"/>
			<rect x="291.386" y="61.714" width="37.028" height="15"/>
			<rect x="346.929" y="61.714" width="67.886" height="15"/>
		</g>
	</g>
</svg>
     );
}

Monitor.defaultProps = {
  size: "20px"
}
Monitor.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default Monitor;