// Soft UI Dashboard React examples
import SuiBox from "components/SuiBox";
import { Upload, ErrorStore, Status } from "components/BackendService";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import SuiTypography from "components/SuiTypography";
import { useNavigate } from "react-router-dom";
import UploadButton from "components/UploadButton";
import { useSelector, useDispatch } from "react-redux";
import { tokenValue, signOut } from "slices/tokenSlice";
import { meValue } from "slices/meSlice";
import { setFileDescription } from "slices/fileDescriptionSlice";
import { addAlert } from "slices/alertsSlice";
import { setProgressEnd } from "slices/apiProgressSlice";

function Uploads() {
  const dispatchRedux = useDispatch()
  const Redirect = useNavigate()
  const { token } = useSelector(tokenValue)
  const { active } = useSelector(meValue)
  const [selectedFiles, setSelectedFiles] = useState(null)
  useEffect(() => {
    if (selectedFiles && active) {
      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append(
        "file",
        selectedFiles[0],
        selectedFiles[0].name
      )
      Upload(token, formData)
        .then(() => {
          Status(token)
            .then(res => {
              dispatchRedux(setFileDescription(res.data.files[0]))
              Redirect('/aituner')
            })
        })
        .then(() => {
          dispatchRedux(setProgressEnd())
        })
        .catch((error) => {
          dispatchRedux(signOut())
          dispatchRedux(addAlert([`${error}`, 'error']))
          ErrorStore(token, `${error}`, 'error')
        })
    }
  }, [selectedFiles])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {token ?
        <SuiBox display='flex' justifyContent='center' alignItems='center'>
          <UploadButton setSelectedFiles={setSelectedFiles} />
        </SuiBox> :
        <SuiTypography fontWeight='bold' sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => Redirect('/sign-in')} >Sign in here</SuiTypography>
      }

    </DashboardLayout >
  );
}

export default Uploads;