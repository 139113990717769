import React, { useState, useEffect, useRef } from 'react';

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox';

// Soft UI Dashboard React examples
import Sidenav from 'examples/Sidenav';

// Soft UI Dashboard React themes
import theme from 'assets/theme';
import darkTheme from 'assets/darkTheme';

// Soft UI Dashboard React routes
import routes from 'routes';

// Soft UI Dashboard React contexts
import { useSoftUIController, setMiniSidenav } from 'context';

// Images
import brand from 'assets/images/logos/J.A.R.V.I.S. whitebackground.png';
import brandDark from 'assets/images/logos/J.A.R.V.I.S. darkbackground.png'
import ClearIcon from '@mui/icons-material/Clear';

import { Me, Solution, Status, Report, Clients, ErrorStore } from 'components/BackendService';
import { Alert, Backdrop, Snackbar } from '@mui/material';
import SuiProgress from 'components/SuiProgress';
import SuiTypography from 'components/SuiTypography';
import { setClients, clearClients } from 'slices/clientsSlice';
import { setReport, clearReport } from 'slices/reportSlice';
import { setFileFeatures, clearFileFeatures } from 'slices/fileFeaturesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { addAlert, clearAlerts, closeAlert, alertsValue } from 'slices/alertsSlice';
import { setProgressEnd, apiProgressValue } from 'slices/apiProgressSlice';
import { darkThemeValue } from 'slices/dakThemeSlice';
import { purchaseValue, clearPurchase } from 'slices/purchaseSlice';
import { setToken, setLoginSource, signOut, tokenValue } from './slices/tokenSlice'
import { setMe, clearMe, setBalance as setBalanceRedux, meValue } from './slices/meSlice'
import { setFileDescription, clearFileDescription, fileDescriptionValue } from './slices/fileDescriptionSlice'



export default function App() {
  const Redirect = useNavigate()
  const dispatchRedux = useDispatch()
  const brandName = `Tuning Files Service`;
  const { token } = useSelector(tokenValue)
  const { admin } = useSelector(meValue)
  const { fileId, completed } = useSelector(fileDescriptionValue)
  const { orderId } = useSelector(purchaseValue)
  const darkThemeRedux = useSelector(darkThemeValue)
  const { alerts, isOpen } = useSelector(alertsValue)
  const { isLoading, progress } = useSelector(apiProgressValue)

  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const didMountToken = useRef(false);

  function LoadGoogle() {
    window.gapi.load('auth2', () => {
      window.auth2 = window.gapi.auth2.init({
        client_id: process.env.REACT_APP_GOOGLE_ID,
        cookiepolicy: 'single_host_origin',
        plugin_name: 'chat'
        // Request scopes in addition to 'profile' and 'email'
        // scope: 'additional_scope'
      })
      window.auth2
        .then(() => {
          if (window.auth2.isSignedIn) {
            if (window.auth2.isSignedIn.get()) {
              console.log('zalogowany przez Google')
              dispatchRedux(setLoginSource('Google'))
              dispatchRedux(setToken(window.gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().id_token))
            }
          }
          else {
            dispatchRedux(addAlert(['Something went wrong', 'error']))
          }
        })
        .catch((error) => {
          dispatchRedux(addAlert([`${error}`, 'error']))
          ErrorStore(token, `${error}`, 'error')
        })
    })
  }
  function LoadFacebook() {
    window.FB.init({
      appId: process.env.REACT_APP_FB_ID,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v16.0'
    })
    window.FB.getLoginStatus((res) => {
      if (res.status) {
        if (res.status === 'connected') {
          console.log('zalogowany przez FB')
          dispatchRedux(setLoginSource('Facebook'))
          dispatchRedux(setToken(res.authResponse.accessToken))
        }
      }
      else {
        dispatchRedux(addAlert(['Something went wrong', 'error']))
      }
    }, true)
  }
  useEffect(() => {
    LoadGoogle()
    LoadFacebook()
  }, [])

  useEffect(() => {
    if (token) {
      dispatchRedux(clearAlerts())
      Me(token)
        .then((res) => {
          window.FB.getLoginStatus((resFB) => {
            if (resFB.status === 'connected' || window?.auth2?.isSignedIn?.get())
              if (res.data) {
                dispatchRedux(setMe(res.data))
              }
          })
        })
        .then(
          Status(token)
            .then((res) => {
              if (res.data.files[0]) {
                dispatchRedux(setFileDescription(res.data.files[0]))
              }
            })
            .catch((error) => {
              dispatchRedux(signOut())
              dispatchRedux(addAlert([`${error}`, 'error']))
              ErrorStore(token, `${error}`, 'error')
            }))
        .then(() => {
          dispatchRedux(addAlert(['Signed in successfully', 'success']))
        })
        .catch((error) => {
          dispatchRedux(signOut())
          dispatchRedux(addAlert([`${error}`, 'error']))
          ErrorStore(token, `${error}`, 'error')
        })
    }
    else if (didMountToken.current) {
      dispatchRedux(clearFileDescription())
      dispatchRedux(clearFileFeatures())
      dispatchRedux(clearMe())
      dispatchRedux(clearClients())
      dispatchRedux(clearReport())
      dispatchRedux(clearPurchase())
      dispatchRedux(addAlert(['Signed out successfully', 'success']))
      Redirect('/', { replace: true })
    }
    else {
      didMountToken.current = true
    }
  }, [token])

  useEffect(() => {
    if (fileId) {
      if (token) {
        Solution(token, fileId)
          .then((res) => {
            if (res.data) {
              dispatchRedux(setFileFeatures(res.data))
            }
            dispatchRedux(addAlert(['File uploaded successfully', 'success']))
          })
          .catch((error) => {
            dispatchRedux(signOut())
            dispatchRedux(addAlert([`${error}`, 'error']))
            ErrorStore(token, `${error}`, 'error')
          })
      }
    }
  }, [fileId])

  useEffect(() => {
    if (orderId) {
      Me(token)
        .then(res => {
          dispatchRedux(setBalanceRedux(res.data.balance))
        })
        .catch((error) => {
          dispatchRedux(signOut())
          dispatchRedux(addAlert([`${error}`, 'error']))
          ErrorStore(token, `${error}`, 'error')
        })
    }
  }, [orderId])

  useEffect(() => {
    if (token && admin) {
      Report(token)
        .then((res) => {
          dispatchRedux(setReport(res.data.solutions))
          dispatchRedux(setProgressEnd())
          dispatchRedux(addAlert(['Report downloaded successfully', 'success']))
        })
        .then(
          Clients(token)
            .then((res) => {
              dispatchRedux(setClients(res.data.clients))
              dispatchRedux(setProgressEnd())
              dispatchRedux(addAlert(['Clients downloaded successfully', 'success']))
            })
            .catch((error) => {
              dispatchRedux(addAlert([`${error}`, 'error']))
              dispatchRedux(signOut())
              ErrorStore(token, `${error}`, 'error')
            }))
        .catch((error) => {
          dispatchRedux(addAlert([`${error}`, 'error']))
          dispatchRedux(signOut())
          ErrorStore(token, `${error}`, 'error')
        })
    }
  }, [admin])

  useEffect(() => {
    console.log(completed)
  }, [completed])

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
    return (
      <ThemeProvider theme={darkThemeRedux.theme ? darkTheme : theme}>
    
      <CssBaseline />
      {layout === 'dashboard' && (
        <Sidenav
          color={sidenavColor}
          brand={darkThemeRedux.theme ? brandDark : brand}
          brandName={brandName}
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      
    
      <Routes>
        {getRoutes(routes)}
        <Route path='*' element={<Navigate to={token ? '/uploads' : '/sign-in'} />} />
      </Routes>
      <Snackbar open={isOpen} onClose={() => dispatchRedux(closeAlert())} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} sx={{ zIndex: theme.zIndex.drawer }}>
        <Alert severity={alerts[alerts.length - 1]?.severity} sx={{ display: 'flex', alignItems: 'center' }}>
          <SuiBox display='flex' alignItems='center' gap={0.5}>
            <SuiTypography sx={{ color: '#495057' }} >
              {alerts[alerts.length - 1]?.message}
            </SuiTypography>
            <ClearIcon sx={{ cursor: 'pointer', height: 20, width: 20, color: '#495057' }} onClick={() => dispatchRedux(closeAlert())} />
          </SuiBox>
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ zIndex: theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <SuiBox display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={5}>
          <SuiTypography sx={{ color: '#dee2e6' }} variant='h2'>
            Loading
          </SuiTypography>
          <SuiProgress value={progress} sx={{ width: '90vw' }} />
        </SuiBox>
      </Backdrop>
    </ThemeProvider>
  )
}