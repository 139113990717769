import { useRef } from 'react';
import GoogleIcon from 'examples/Icons/GoogleIcon';
import { useNavigate } from 'react-router-dom';
import SuiButton from 'components/SuiButton';
import { ErrorStore } from 'components/BackendService';
import { useDispatch, useSelector } from 'react-redux';
import { setToken, setLoginSource, tokenValue } from 'slices/tokenSlice';
import { addAlert } from 'slices/alertsSlice';

function GoogleLogin({ ...rest }) {
  const Redirect = useNavigate()
  const dispatchRedux = useDispatch()
  const { token } = useSelector(tokenValue)
  const loginButton = useRef(null);
  const buttonSize = 30
  const styles = { 'cursor': 'pointer', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center', 'borderRadius': '100%', 'width': `${buttonSize + 6}px`, 'height': `${buttonSize + 6}px` }

  function GetToken() {
    const auth2 = window.gapi.auth2.getAuthInstance();
    if (!auth2?.isSignedIn?.get()) {
      console.log('loguje przez Google')
      auth2?.signIn({
        scope: 'profile email',
      })
        .then((response) => {
          dispatchRedux(setToken(response.getAuthResponse().id_token))
          dispatchRedux(setLoginSource('Google'))
          Redirect('/')
        })
        .catch(err => {
          dispatchRedux(addAlert([`${err.error}`, 'error']))
          ErrorStore(token, `${err.error}`, 'error')
        })
    }
    else {
      console.log('zalogowany przez Google')
    }
  }

  return (
    <SuiButton onClick={() => GetToken()} {...rest}>
      <div id='customBtn' ref={loginButton} style={styles} role='button' tabIndex={0}>
        <GoogleIcon size={buttonSize} />
      </div>
    </SuiButton>
  );
}

export default GoogleLogin;