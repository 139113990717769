import { configureStore } from '@reduxjs/toolkit'
import meReducer from './slices/meSlice'
import fileDescriptionReducer from './slices/fileDescriptionSlice'
import fileFeaturesReducer from './slices/fileFeaturesSlice'
import clientsReducer from './slices/clientsSlice'
import reportReducer from './slices/reportSlice'
import tokenReducer from './slices/tokenSlice'
import apiProgressReducer from './slices/apiProgressSlice'
import darkThemeReducer from './slices/dakThemeSlice'
import purchaseReducer from './slices/purchaseSlice'
import alertsReducer from './slices/alertsSlice'

const store = configureStore({
    reducer: {
        me: meReducer,
        fileDescription: fileDescriptionReducer,
        fileFeatures: fileFeaturesReducer,
        clients: clientsReducer,
        report: reportReducer,
        token: tokenReducer,
        apiProgress: apiProgressReducer,
        darkTheme: darkThemeReducer,
        purchase: purchaseReducer,
        alerts: alertsReducer
    },
})
export default store