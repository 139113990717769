import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    theme: true
}

export const darkThemeSlice = createSlice({
    name: 'darkTheme',
    initialState,
    reducers: {
        changeTheme: (state) => {
            state.theme = !state.theme
        }
    },
})

// Action creators are generated for each case reducer function
export const { changeTheme } = darkThemeSlice.actions
export const darkThemeValue = (state) => state.darkTheme

export default darkThemeSlice.reducer