import axios from 'axios';
import { setProgressEnd, setProgress } from 'slices/apiProgressSlice';
import store from 'store';

const URL = 'https://backend.tuningfile.online/backend'
const backend = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

export function Me(token) {
  return new Promise((resolve, reject) => {
    backend.post('me', {}, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function Upload(token, formData) {
  return new Promise((resolve, reject) => {
    backend.post('upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      },
      onUploadProgress: (event) => {
        store.dispatch(setProgress(Math.round((100 * event.loaded) / event.total)))
      },
    })
      .then(() => {
        store.dispatch(setProgressEnd())
      })
      .then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function Solution(token, fileId) {
  return new Promise((resolve, reject) => {
    backend.post('solution', fileId, {
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${token}`
      }
    }).then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function Features(token, features) {
  return new Promise((resolve, reject) => {
    backend.post('features', { features }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function DownloadDTC(token, fileId) {
  return new Promise((resolve, reject) => {
    backend.post('download-dtcs', fileId, {
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob',
      onDownloadProgress: (event) => {
        store.dispatch(setProgress(Math.round((100 * event.loaded) / event.total)))
      }
    }).then((res) => {
      const suggested = res.request.getResponseHeader(
        'X-Suggested-Filename'
      );
      const blob = new Blob([res.data], {
        type: 'application/octet-stream',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = suggested;
      link.click();
    })
      .then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function Download(token, fileId) {
  return new Promise((resolve, reject) => {
    backend.post('download', fileId, {
      headers: {
        'Content-Type': 'text/plain',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob',
      onDownloadProgress: (event) => {
        store.dispatch(setProgress(Math.round((100 * event.loaded) / event.total)))
      }
    }).then((res) => {
      const suggested = res.request.getResponseHeader(
        'X-Suggested-Filename'
      );
      const blob = new Blob([res.data], {
        type: 'application/octet-stream',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = suggested;
      link.click();
    })
      .then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function Status(token) {
  return new Promise((resolve, reject) => {
    backend.post('status', {}, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function CreateOrder(token, item) {
  return new Promise((resolve, reject) => {
    backend.post('create-order', { item }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }).then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function CaptureOrder(token, orderId) {
  return new Promise((resolve, reject) => {
    backend.post('capture-order', { orderId }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    }).then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function Report(token) {
  return new Promise((resolve, reject) => {
    backend.post('/report', {}, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      onDownloadProgress: (event) => {
        store.dispatch(setProgress(Math.round((100 * event.loaded) / event.total)))
      }
    }).then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function File(token, clientId, fileId) {
  return new Promise((resolve, reject) => {
    backend.post('file', { clientId, fileId }, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob',
      onDownloadProgress: (event) => {
        store.dispatch(setProgress(Math.round((100 * event.loaded) / event.total)))
      }
    }).then((res) => {
      const suggested = res.request.getResponseHeader(
        'X-Suggested-Filename'
      );
      const blob = new Blob([res.data], {
        type: 'application/octet-stream',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = suggested;
      link.click();
    })
      .then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function Clients(token) {
  return new Promise((resolve, reject) => {
    backend.post(
      "/clients",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (event) => {
          store.dispatch(setProgress(Math.round((100 * event.loaded) / event.total)))
        },
      }
    )
      .then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function Register(token, client) {
  return new Promise((resolve, reject) => {
    backend.post(
      "/register",
      client,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function Emergency(token, uuid, purchased) {
  return new Promise((resolve, reject) => {
    backend.post(
      "/emergency",
      { orderId: uuid, purchased },
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
      }
    )
      .then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function Scripts(token, formData) {
  return new Promise((resolve, reject) => {
    backend.post(
      "/scripts", formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`
        },
        onUploadProgress: (event) => {
          store.dispatch(setProgress(Math.round((100 * event.loaded) / event.total)))
        },
      }
    )
      .then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function Emails(token) {
  return new Promise((resolve, reject) => {
    backend.post('emails', {}, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob',
      onDownloadProgress: (event) => {
        store.dispatch(setProgress(Math.round((100 * event.loaded) / event.total)))
      }
    }).then((res) => {
      const suggested = res.request.getResponseHeader(
        'X-Suggested-Filename'
      );
      const blob = new Blob([res.data], {
        type: 'application/octet-stream',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = suggested;
      link.click();
    })
      .then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export function ErrorStore(token, message, severity) {
  return new Promise((resolve, reject) => {
    backend.post(
      "/error",
      { message, severity },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then(res => resolve(res))
      .catch(err => reject(new Error(err)))
  })
}

export default function EndpointError() {
  console.log('error')
}