// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

function Power({ size, color }) {
    return (
        <svg version="1.1" id="designs" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            fill={color}
            width={size} height={size} viewBox="0 0 32 32" style={{ enableBackground: 'new 0 0 32 32' }} xmlSpace="preserve">
            <path className="sketchy_een" d="M30.998,18.313c0.006-0.687,0-1.373-0.008-2.06c-0.008-0.711-0.024-1.42-0.024-2.131
	c0.002-0.809,0.006-1.617-0.004-2.426c-0.006-0.444-0.355-0.807-0.795-0.831c-0.135-0.285-0.422-0.491-0.754-0.489
	c-0.548,0.004-1.097,0.002-1.645,0c-0.333-0.001-0.666-0.002-0.999-0.002c-0.095,0-0.19,0.001-0.285,0.001
	c0.008-1.29,0.02-2.58,0.024-3.871c0.002-0.443-0.369-0.812-0.812-0.812c-0.072,0-0.138,0.024-0.205,0.042
	c-0.011,0-0.021-0.007-0.032-0.007c-0.003,0-0.007,0-0.01,0c-1.605,0.02-3.208,0.03-4.811,0.06c-0.828,0.016-1.657,0.03-2.485,0.036
	c-0.822,0.004-1.645,0.036-2.467,0.052c-1.663,0.032-3.325,0.099-4.988,0.099c-0.814,0-1.627-0.004-2.439,0.006
	C7.815,5.984,7.372,5.991,6.931,6.013c-0.393,0.02-0.787,0.048-1.182,0.048c-1.202,0-2.404-0.032-3.604-0.014
	c-0.15,0.002-0.285,0.054-0.408,0.125C1.332,6.204,0.998,6.541,1,6.954c0.004,1.651-0.01,3.302,0.022,4.952
	c0.032,1.587,0.077,3.172,0.135,4.76c0.052,1.47,0.068,2.944,0.066,4.416c0,1.48,0.024,2.964-0.004,4.446
	c-0.008,0.425,0.362,0.781,0.783,0.781c0.135,0,0.258-0.046,0.372-0.11c0.017,0.001,0.032,0.01,0.05,0.01
	c0.964-0.016,1.923-0.058,2.884-0.101c0.86-0.038,1.72-0.042,2.58-0.054c0.84-0.014,1.681-0.048,2.521-0.053
	c0.872-0.008,1.744-0.008,2.618-0.004c1.768,0.01,3.538-0.024,5.306-0.046c1.691-0.022,3.381-0.02,5.072-0.012
	c0.783,0.004,1.567,0.028,2.35,0.028c0.342-0.001,0.635-0.206,0.787-0.491c0.116-0.038,0.224-0.094,0.311-0.181
	c0.157-0.157,0.217-0.346,0.232-0.56c0.04-0.554,0.036-1.108,0.038-1.665c0.001-0.449,0.004-0.899,0.006-1.348
	c0.461-0.008,0.923-0.015,1.384,0.007c0.312,0.016,0.626,0.038,0.938,0.064c0.308,0.028,0.592,0.046,0.898-0.02
	c0.364-0.077,0.616-0.453,0.616-0.808c0-0.136-0.042-0.26-0.101-0.375c0.061-0.111,0.106-0.232,0.111-0.366
	C31.002,19.586,30.992,18.947,30.998,18.313z M26.382,19.972c-0.479-0.002-0.878,0.401-0.878,0.88c0,0.041,0.018,0.077,0.024,0.116
	c-0.015,0.062-0.039,0.121-0.039,0.188c0.002,0.624,0.008,1.25,0.01,1.875c0.001,0.377,0,0.756,0,1.135
	c-0.748,0-1.497,0.023-2.244,0.028c-0.83,0.004-1.661,0.022-2.491,0.038c-1.768,0.032-3.536,0.044-5.304,0.072
	c-1.736,0.03-3.474,0.034-5.213,0.056c-0.838,0.01-1.679,0.048-2.517,0.067c-0.86,0.02-1.718,0.032-2.578,0.052
	c-0.792,0.019-1.583,0.034-2.375,0.043c-0.009-1.256,0.004-2.511-0.005-3.765c-0.01-1.607-0.04-3.21-0.083-4.813
	c-0.042-1.456-0.085-2.912-0.103-4.368c-0.017-1.292-0.006-2.581-0.001-3.871C3.322,7.687,4.06,7.67,4.798,7.65
	c0.399-0.012,0.801-0.014,1.2-0.032s0.799-0.05,1.198-0.07c0.995-0.05,1.992-0.043,2.99-0.038c0.689,0.004,1.381,0.008,2.07-0.008
	c1.641-0.034,3.282-0.063,4.923-0.101c1.641-0.038,3.282-0.02,4.923-0.006c0.928,0.007,1.858,0.017,2.788,0.028
	c0.004,0.495,0.009,0.99,0.01,1.484c0.004,0.715-0.01,1.432,0.016,2.147c0.001,0.026,0.013,0.049,0.016,0.074
	c-0.005,0.038-0.023,0.071-0.023,0.111c0,0.465,0.393,0.872,0.862,0.864c1.174-0.023,2.345-0.059,3.52-0.053
	c0.022,1.38,0.035,2.762,0.073,4.142c0.036,1.292,0.081,2.588,0.057,3.882c-0.302-0.028-0.606-0.046-0.908-0.06
	C27.803,19.983,27.093,19.976,26.382,19.972z M19.772,10.197c-0.027-0.002-0.051-0.016-0.078-0.015
	c-1.027,0.018-2.054,0.008-3.081,0c-0.646-0.005-1.29-0.012-1.934-0.012c-0.07,0-0.139,0-0.209,0
	c-0.117-0.054-0.242-0.088-0.372-0.088c-0.213,0-0.499,0.085-0.636,0.262c-0.626,0.811-1.244,1.623-1.79,2.489
	c-0.437,0.695-0.85,1.404-1.255,2.116c-0.199,0.347-0.435,0.671-0.632,1.021c-0.161,0.286-0.27,0.59-0.306,0.916
	c-0.06,0.514,0.258,0.997,0.725,1.196c0.23,0.097,0.443,0.161,0.689,0.199c0.254,0.038,0.507,0.06,0.765,0.073
	c0.165,0.008,0.332,0.008,0.499,0.006c0.002,0,0.003,0,0.005,0c-0.047,0.139-0.099,0.276-0.154,0.412
	c-0.314,0.717-0.677,1.412-0.987,2.13c-0.06,0.137-0.127,0.276-0.163,0.423c-0.07,0.294-0.006,0.616,0.213,0.836
	c0.222,0.22,0.513,0.276,0.814,0.22c0.163-0.03,0.32-0.131,0.461-0.217c0.314-0.193,0.636-0.377,0.94-0.586
	c0.687-0.469,1.331-1.011,1.943-1.571c0.624-0.572,1.255-1.138,1.861-1.728c0.33-0.32,0.66-0.646,0.979-0.977
	c0.163-0.171,0.324-0.342,0.485-0.515c0.081-0.087,0.163-0.173,0.242-0.262c0.131-0.143,0.278-0.296,0.356-0.475
	c0.028-0.065,0.056-0.131,0.081-0.195c0.014-0.087,0.026-0.177,0.036-0.266c0.022-0.201-0.113-0.483-0.254-0.614
	c-0.213-0.197-0.461-0.298-0.741-0.367c-0.233-0.059-0.474-0.092-0.712-0.121c0.302-0.3,0.598-0.605,0.889-0.914
	c0.32-0.342,0.648-0.674,0.969-1.013c0.324-0.34,0.642-0.685,0.987-1.005c0.324-0.298,0.302-0.838,0-1.14
	C20.228,10.237,19.998,10.175,19.772,10.197z M16.485,13.23c-0.252,0.242-0.509,0.481-0.761,0.723
	c-0.389,0.369-0.938,0.783-0.771,1.395c0.083,0.302,0.308,0.518,0.604,0.616c0.141,0.048,0.308,0.056,0.459,0.058
	c0.153,0,0.306-0.004,0.457-0.002c0.189,0.004,0.376,0.013,0.564,0.025c-0.132,0.137-0.266,0.271-0.399,0.404
	c-0.471,0.469-0.942,0.934-1.432,1.381c-0.595,0.54-1.178,1.089-1.781,1.616c0.097-0.218,0.188-0.439,0.27-0.665
	c0.111-0.31,0.183-0.616,0.209-0.946c0.028-0.352-0.163-0.787-0.493-0.949c-0.133-0.068-0.262-0.121-0.409-0.145
	c-0.121-0.02-0.244-0.034-0.367-0.044c-0.414-0.03-0.829-0.013-1.243-0.047c0.151-0.243,0.319-0.478,0.467-0.721
	c0.232-0.385,0.435-0.787,0.669-1.17c0.262-0.431,0.528-0.858,0.807-1.279c0.26-0.396,0.557-0.769,0.841-1.146
	c0.126-0.164,0.254-0.326,0.381-0.489c1.117-0.01,2.235-0.032,3.353-0.051c-0.004,0.004-0.008,0.008-0.012,0.012
	C17.425,12.279,16.968,12.767,16.485,13.23z"/>
        </svg>
    );
}

Power.defaultProps = {
    size: "20px",
    color: 'black'
}
Power.propTypes = {
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.string
}

export default Power;