import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useSelector } from "react-redux";
import { darkThemeValue } from "slices/dakThemeSlice";
import { meValue } from "slices/meSlice";

function TrainingVideos() {
  const { theme } = useSelector(darkThemeValue)
  const { active } = useSelector(meValue)
  const videos = [
    {
      header: 'edc16cp31 training',
      title: '☢️  mercedes-cls320cdi-stage-1-and-2-tutorial  ☢️',
      link: 'https://www.acetuningfileservice.com/post/mercedes-cls320cdi-stage-1-and-2-tutorial'
    },
    {
      header: 'edc17c84 training',
      title: '☢️  edc17c84-tuning-tutorial-video-stage-1  ☢️',
      link: 'https://www.acetuningfileservice.com/post/bosch-edc17c84-tuning-tutorial-video-stage-1'
    },
    {
      header: 'edc17cp14 training',
      title: '☢️  edc17cp14-audi-a5-2-0tdicr-143hp  ☢️',
      link: 'https://www.acetuningfileservice.com/post/edc17cp14-audi-a5-2-0tdicr-143hp'
    },
    {
      header: 'edc17c41 training',
      title: '☢️  bmw-f10-520d-184hp-edc17c41  ☢️',
      link: 'https://www.acetuningfileservice.com/post/bmw-f10-520d-184hp-edc17c41'
    },
    {
      header: 'med9 tfsi training',
      title: '',
      link: ''
    },
    {
      header: 'med17 tfsi training',
      title: '',
      link: ''
    }]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox display='flex' flexDirection='column' alignItems='center'>
        <SuiTypography variant="h1" p={5} textAlign="center" fontWeight="bold">
          Training Videos
        </SuiTypography>
        <Accordion sx={{ backgroundColor: theme ? '#343a40' : 'white', width: '100%' }} disabled={!active}>
          <AccordionSummary>
            <SuiTypography>
              Coming soon
            </SuiTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <iframe
              title='training'
              width="740"
              height="416"
              src="https://www.youtube.com/embed/izdgz73EnGk"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </AccordionDetails>
        </Accordion>
        {videos.map(({ header, title, link }) =>
          <Accordion sx={{ backgroundColor: theme ? '#343a40' : 'white', width: '100%' }} disabled={!active}>
            <AccordionSummary>
              <SuiTypography>
                {header}
              </SuiTypography>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <a href={link} target='_blank' rel="noreferrer">{title}</a>
            </AccordionDetails>
          </Accordion>
        )}
      </SuiBox>
    </DashboardLayout >
  );
}

export default TrainingVideos;