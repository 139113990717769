import { Register, ErrorStore } from "components/BackendService";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import PropTypes from 'prop-types';
import SuiTypography from "components/SuiTypography";
import { useEffect, useRef } from "react";
import { Table } from 'react-bootstrap';
import SortIcon from "examples/Icons/SortIcon";
import { useDispatch, useSelector } from "react-redux";
import { darkThemeValue } from "slices/dakThemeSlice";
import { tokenValue, signOut } from "slices/tokenSlice";
import { addAlert } from "slices/alertsSlice";

function TableComponent({ usersPage, sortColumn, searchedClients, setSearchedClients, setSortColumn, ...rest }) {
    const dispatchRedux = useDispatch()
    const { theme } = useSelector(darkThemeValue)
    const { token } = useSelector(tokenValue)

    const columns = ['clientId', 'blocked', 'canSubscribe', 'amount', 'amountSubscription']
    const headColumns = ['Client', 'Status', 'Can Subscribe?', 'Amount', 'Subscription']
    const didMount = useRef(false);
    const sortTable = (index) => {
        if (sortColumn === columns[index]) {
            setSearchedClients([...searchedClients].reverse())
        }
        else {
            setSortColumn(columns[index])
        }
    }
    useEffect(() => {
        if (didMount.current) {
            if (columns.indexOf(sortColumn) >= 0) {
                const tempUsersData = [...searchedClients]
                tempUsersData.sort((a, b) => {
                    if (a[sortColumn] < b[sortColumn]) {
                        return -1;
                    }
                    if (a[sortColumn] > b[sortColumn]) {
                        return 1;
                    }
                    return 0
                }).reverse()
                setSearchedClients(tempUsersData)
            }
            return
        }
        didMount.current = true
    }, [sortColumn])

    return (
        <Table bordered variant={theme ? 'dark' : ''} style={{ ...rest }}>
            <thead>
                <tr>
                    {headColumns.map((column, columnIndex) =>
                        <th key={column}>
                            <SuiBox display='flex' alignItems='center' justifyContent='center' gap={3}>
                                {column}
                                <SuiBox
                                    onClick={() => sortTable(columnIndex)}
                                    sx={{ cursor: 'pointer' }}>
                                    <SortIcon color={theme ? 'white' : 'black'} />
                                </SuiBox>
                            </SuiBox>
                        </th>
                    )}
                </tr>
            </thead>
            <tbody>
                {searchedClients?.length > 0 && searchedClients.slice(usersPage * 10, usersPage * 10 + 10).map((user) =>
                    <tr key={user.clientId}>
                        {columns.map((column) =>
                            Object.keys(user).map((optionKey) => {
                                if (column === optionKey) {
                                    if (optionKey === 'canSubscribe') {
                                        return (
                                            <td>
                                                <SuiButton sx={{ width: '100%' }} color={user[optionKey] ? 'light' : 'white'} onClick={() => {
                                                    setSearchedClients(
                                                        searchedClients.map(tempUser =>
                                                            tempUser.clientId === user.clientId ?
                                                                { ...tempUser, [optionKey]: !tempUser[optionKey] } : tempUser
                                                        )
                                                    )
                                                }}>
                                                    {user[optionKey] ? 'Can Subscribe' : 'Cannot subscribe'}
                                                </SuiButton>
                                            </td>
                                        )
                                    }
                                    if (optionKey === 'blocked') {
                                        return (
                                            <td>
                                                <SuiBox width={100} sx={{ marginInline: 'auto' }}>
                                                    <SuiButton sx={{ width: '100%' }} color={user[optionKey] ? 'light' : 'white'} onClick={() => {
                                                        setSearchedClients(
                                                            searchedClients.map(tempUser =>
                                                                tempUser.clientId === user.clientId ?
                                                                    { ...tempUser, [optionKey]: !tempUser[optionKey] } : tempUser
                                                            )
                                                        )
                                                    }}>
                                                        {user[optionKey] ? 'Blocked' : 'Active'}
                                                    </SuiButton>
                                                </SuiBox>
                                            </td>
                                        )
                                    }
                                    if (optionKey === 'amount' || optionKey === 'amountSubscription') {
                                        return (
                                            <td>
                                                <SuiBox display='flex'>
                                                    <SuiButton onClick={() => {
                                                        setSearchedClients(
                                                            searchedClients.map(tempUser =>
                                                                tempUser.clientId === user.clientId ?
                                                                    { ...tempUser, [optionKey]: tempUser[optionKey] - 1 } : tempUser
                                                            )
                                                        )
                                                    }} sx={{ minWidth: { md: '64px', sm: '48px', xs: '32px' } }}>-</SuiButton>
                                                    <SuiInput value={user[optionKey]} onChange={(e) => {
                                                        setSearchedClients(
                                                            searchedClients.map(tempUser =>
                                                                tempUser.clientId === user.clientId ?
                                                                    { ...user, [optionKey]: Number(e.target.value) } : tempUser
                                                            )
                                                        )
                                                    }} sx={{ minWidth: { md: '64px', sm: '48px', xs: '32px' } }} />
                                                    <SuiButton onClick={() => {
                                                        setSearchedClients(
                                                            searchedClients.map(tempUser =>
                                                                tempUser.clientId === user.clientId ?
                                                                    { ...tempUser, [optionKey]: tempUser[optionKey] + 1 } : tempUser
                                                            )
                                                        )
                                                    }} sx={{ minWidth: { md: '64px', sm: '48px', xs: '32px' } }}>+</SuiButton>
                                                </SuiBox>
                                            </td>
                                        )
                                    }
                                    return (
                                        <td>
                                            <SuiTypography sx={{ wordBreak: 'break-all', width: 200 }}>
                                                {user[optionKey]}
                                            </SuiTypography>
                                        </td>
                                    )
                                }
                                return null
                            })
                        )
                        }
                        <td>
                            <SuiBox display='flex' justifyContent='center'>
                                <SuiButton onClick={() => {
                                    Register(token, user)
                                        .then(() => {
                                            dispatchRedux(addAlert(['User updated successfully', 'success']))
                                        })
                                        .catch((error) => {
                                            dispatchRedux(signOut())
                                            dispatchRedux(addAlert([`${error}`, 'error']))
                                            ErrorStore(token, `${error}`, 'error')
                                        })
                                }}>
                                    Update
                                </SuiButton>
                            </SuiBox>
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
    );
}
TableComponent.defaultProps = {
    usersPage: PropTypes.number,
    sortColumn: PropTypes.string,
    setSortColumn: PropTypes.func,
    searchedClients: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string]))),
    setSearchedClients: PropTypes.func
};

TableComponent.propTypes = {
    usersPage: 0,
    sortColumn: '',
    setSortColumn: () => { },
    searchedClients: [],
    setSearchedClients: () => { }
};

export default TableComponent;