import PaginationTab from "components/PaginationTab";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useReducer, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setProgressEnd } from "slices/apiProgressSlice";
import { meValue } from "slices/meSlice";
import { reportValue } from "slices/reportSlice";
import RefreshButton from "./components/RefreshButton";
import TableComponent from "./components/Table";

function ReportComponent() {
    const dispatchRedux = useDispatch()
    const { admin } = useSelector(meValue)
    const report = useSelector(reportValue)
    const [sortColumn, setSortColumn] = useState('')
    const [searchedFiles, setSearchedFiles] = useState([])
    function reducer(state, action) {
        switch (action.type) {
            case 'decrement':
                if (state.page >= 10) {
                    return { page: state.page - 10 }
                }
                return { page: 0 }
            case 'increment':
                if (state.page <= Math.ceil(searchedFiles.length / 10) - 12) {
                    return { page: state.page + 10 }
                }
                return { page: Math.ceil(searchedFiles.length / 10) - 1 }
            case 'setPage':
                return { page: action.page }
            default:
                throw new Error();
        }
    }
    const [usersPage, dispatch] = useReducer(reducer, { page: 0 });
    const searchFiles = (e) => {
        setSearchedFiles([])
        report.forEach((user) => {
            if (user.clientId.indexOf(e.target.value) >= 0) {
                setSearchedFiles(prev => [...prev, user])
                setSortColumn('')
            }
        })
        dispatch({ type: 'setPage', page: 0 })
    }
    useEffect(() => {
        if (admin) {
            if (Object.keys(report).length === 0) {
                // dispatchRedux(setLoading())
            }
            else {
                dispatchRedux(setProgressEnd())
                setSearchedFiles(report)
            }
        }
    }, [report])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {admin ?
                report.length > 0 &&
                <SuiBox display='flex' flexDirection='column' justifyContent='center' gap={2} sx={{ alignItems: { xs: 'flex-start', sm: 'center' } }}>
                    <RefreshButton alignSelf='flex-start' setSortColumn={setSortColumn} />
                    <PaginationTab usersPage={usersPage.page} dispatch={dispatch} data={searchedFiles} />
                    <SuiInput
                        placeholder="Search files"
                        icon={{ component: "search", direction: "left" }}
                        onChange={searchFiles}
                    />
                    {searchedFiles.length > 0 ?
                        <TableComponent usersPage={usersPage.page} alignSelf='flex-start' sortColumn={sortColumn} setSortColumn={setSortColumn} searchedFiles={searchedFiles} setSearchedFiles={setSearchedFiles} /> :
                        <SuiBox>
                            No matching result
                        </SuiBox>
                    }
                    <PaginationTab usersPage={usersPage.page} dispatch={dispatch} data={searchedFiles} />
                </SuiBox> :
                <SuiTypography fontWeight='bold'>
                    You&apos;re not an admin
                </SuiTypography>
            }

        </DashboardLayout>
    );
}

export default ReportComponent;
