import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        setReport: (state, action) => {
            state.length = 0
            state.push(...action.payload)
        },
        clearReport: (state) => {
            state.length = 0
        }
    },
})

// Action creators are generated for each case reducer function
export const { setReport, clearReport } = reportSlice.actions
export const reportValue = (state) => state.report

export default reportSlice.reducer