import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useReducer, useState } from "react";
import SuiTypography from "components/SuiTypography";
import PaginationTab from "components/PaginationTab";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { Emails } from "components/BackendService";
import { useDispatch, useSelector } from "react-redux";
import { clientsValue } from "slices/clientsSlice";
import { meValue } from "slices/meSlice";
import { tokenValue } from "slices/tokenSlice";
import { setProgressEnd } from "slices/apiProgressSlice";
import RefreshButton from "./components/RefreshButton";
import TableComponent from "./components/Table";

function Report() {
    const dispatchRedux = useDispatch()
    const { token } = useSelector(tokenValue)
    const { admin } = useSelector(meValue)
    const clients = useSelector(clientsValue)
    const [sortColumn, setSortColumn] = useState('')
    const [searchedClients, setSearchedClients] = useState([])
    function reducer(state, action) {
        switch (action.type) {
            case 'decrement':
                if (state.page >= 10) {
                    return { page: state.page - 10 }
                }
                return { page: 0 }
            case 'increment':
                if (state.page <= Math.ceil(searchedClients.length / 10) - 12) {
                    return { page: state.page + 10 }
                }
                return { page: Math.ceil(searchedClients.length / 10) - 1 }
            case 'setPage':
                return { page: action.page }
            default:
                throw new Error();
        }
    }
    const [usersPage, dispatch] = useReducer(reducer, { page: 0 });
    const searchClients = (e) => {
        setSearchedClients([])
        clients.forEach((user) => {
            if (user.clientId.indexOf(e.target.value) >= 0) {
                setSearchedClients(prev => [...prev, user])
                setSortColumn('')
            }
        })
        dispatch({ type: 'setPage', page: 0 })
    }
    useEffect(() => {
        if (admin) {
            if (Object.keys(clients).length === 0) {
                // dispatchRedux(setLoading())
            }
            else {
                setSearchedClients(clients)
            }
        }
    }, [clients])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {admin ?
                clients.length > 0 &&
                <SuiBox display='flex' flexDirection='column' justifyContent='center' gap={2} sx={{ alignItems: { xs: 'flex-start', sm: 'center' } }}>
                    <SuiBox width='100%' display='flex' alignItems='flex-start' gap={5}>
                        <RefreshButton alignSelf='flex-start' setSortColumn={setSortColumn} />
                        <SuiButton
                            onClick={() => {
                                Emails(token)
                                    .then(() => {
                                        dispatchRedux(setProgressEnd())
                                    })
                            }}
                        >Emails</SuiButton>
                    </SuiBox>
                    <PaginationTab usersPage={usersPage.page} dispatch={dispatch} data={searchedClients} />
                    <SuiInput
                        placeholder="Search clients"
                        icon={{ component: "search", direction: "left" }}
                        onChange={searchClients}
                    />
                    {searchedClients.length > 0 ?
                        <TableComponent usersPage={usersPage.page} alignSelf='flex-start' sortColumn={sortColumn} setSortColumn={setSortColumn} searchedClients={searchedClients} setSearchedClients={setSearchedClients} /> :
                        <SuiBox>
                            No matching result
                        </SuiBox>
                    }
                    <PaginationTab usersPage={usersPage.page} dispatch={dispatch} data={searchedClients} />
                </SuiBox> :
                <SuiTypography fontWeight='bold'>
                    You&apos;re not an admin
                </SuiTypography>
            }
        </DashboardLayout>
    );
}

export default Report;
