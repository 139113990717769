// Soft UI Dashboard React examples
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useSelector } from "react-redux";
import { darkThemeValue } from "slices/dakThemeSlice";

function FAQ() {
  const { theme } = useSelector(darkThemeValue)
  const faqData = [{
    question:
      'How to proceed with DPF removal procedure?',
    answer: [
      'do diagnostic scan',
      'write down codes, clear them, reset dpf data ( program dpf change or force regeneration )',
      'check for codes again',
      'note down remaining (with descriptions) and proceed to read the ecu content',
      'DO NOT START ENGINE IN BETWEEN U READ THE FILE AND WRITE NEW FILE FROM US !!!',
      'write new file and enjoy job done',
      'in some cases (damaged sensors) we may need to manualy delete some remaining dtcs even after module was switched off. Read codes send to us and ask for revision.']
  }]
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiTypography variant="h1" p={5} textAlign="center" fontWeight="bold">
        Frequently Asked Questions
      </SuiTypography>
      {faqData.map((QnA) =>
        <Accordion sx={{ backgroundColor: theme ? '#343a40' : 'white' }}>
          <AccordionSummary>
            <SuiTypography>
              {QnA.question}
            </SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <ul style={{ listStyle: 'none', display: 'flex', flexDirection: 'column', gap: '1em' }}>
              {[...QnA.answer].map((answer, index) =>
                <li>
                  <SuiTypography>
                    <span style={{ marginRight: '1em', fontWeight: 'bold', fontSize: '1.2em' }}>{index + 1}</span>
                    {answer}
                  </SuiTypography>
                </li>
              )}
            </ul>
          </AccordionDetails>
        </Accordion>
      )}
    </DashboardLayout >
  );
}

export default FAQ;