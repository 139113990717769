// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

function Person({ size, color }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="🔍-System-Icons" stroke="none" strokeWidth="1" fillRule="evenodd">
        <g id="ic_fluent_person_add_24_regular" fill={color} fillRule="nonzero">
          <path d="M17.5,12 C20.5376,12 23,14.4624 23,17.5 C23,20.5376 20.5376,23 17.5,23 C14.4624,23 12,20.5376 12,17.5 C12,14.4624 14.4624,12 17.5,12 Z M12.0223,13.9993 C11.7256,14.4626 11.486,14.966 11.3136,15.4996 L4.25242,15.4999 C3.83882,15.4999 3.50354,15.8352 3.50354,16.2488 L3.50354,16.8265 C3.50354,17.3622 3.69465,17.8802 4.04251,18.2876 C5.29582,19.7553 7.2617,20.5011 10,20.5011 C10.5964,20.5011 11.1563,20.4657 11.6802,20.3952 C11.9254,20.8901 12.233,21.3486 12.5917,21.7615 C11.7962,21.9217 10.9314,22.0011 10,22.0011 C6.85414,22.0011 4.46812,21.0959 2.90182,19.2617 C2.32206,18.5828 2.00354,17.7193 2.00354,16.8265 L2.00354,16.2488 C2.00354,15.0068 3.0104,13.9999 4.25242,13.9999 L12.0223,13.9993 Z M17.5,14 L17.4101,14.0081 C17.206,14.0451 17.0451,14.206 17.0081,14.4101 L17,14.5 L16.999,17 L14.5039,17 L14.414,17.0081 C14.2099,17.0451 14.049,17.206 14.012,17.4101 L14.0039,17.5 L14.012,17.5899 C14.049,17.794 14.2099,17.9549 14.414,17.992 L14.5039,18 L16.999,18 L17,20.5 L17.0081,20.5899 C17.0451,20.794 17.206,20.9549 17.4101,20.9919 L17.5,21 L17.5899,20.9919 C17.794,20.9549 17.9549,20.794 17.9919,20.5899 L18,20.5 L17.999,18 L20.5039,18 L20.5938,17.992 C20.7979,17.9549 20.9588,17.794 20.9958,17.5899 L21.0039,17.5 L20.9958,17.4101 C20.9588,17.206 20.7979,17.0451 20.5938,17.0081 L20.5039,17 L17.999,17 L18,14.5 L17.9919,14.4101 C17.9549,14.206 17.794,14.0451 17.5899,14.0081 L17.5,14 Z M10,2.00464 C12.7614,2.00464 15,4.24321 15,7.00464 C15,9.76606 12.7614,12.0046 10,12.0046 C7.23857,12.0046 5,9.76606 5,7.00464 C5,4.24321 7.23857,2.00464 10,2.00464 Z M10,3.50464 C8.067,3.50464 6.5,5.07164 6.5,7.00464 C6.5,8.93764 8.067,10.5046 10,10.5046 C11.933,10.5046 13.5,8.93764 13.5,7.00464 C13.5,5.07164 11.933,3.50464 10,3.50464 Z" id="🎨-Color" />
        </g>
      </g>
    </svg>
  );
}

Person.defaultProps = {
  size: "20px",
  color: 'black'
}
Person.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string
}

export default Person;