// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI React examples
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { darkThemeValue } from "slices/dakThemeSlice";
import { useSelector } from "react-redux";
import { tokenValue } from "slices/tokenSlice";
import GoogleLogin from "./components/Socials/GoogleLogin";
import FacebookLogin from "./components/Socials/FacebookLogin";

// Authentication layout components

function SignIn() {
  const Redirect = useNavigate()
  const { token } = useSelector(tokenValue)
  const { theme } = useSelector(darkThemeValue)
  useEffect(() => {
    if (token) {
      Redirect('/', { replace: true })
    }
  })
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!token &&
        <SuiBox display='flex' justifyContent='center' alignItems={{ xs: 'start', sm: 'center' }} height={{ xs: 550, sm: 700 }}>
          <SuiBox color="white" bgColor="white" borderRadius="lg" shadow="lg" opacity={1} p={3} width={{ xs: 350, sm: 400 }} height={400}>
            <SuiBox p={3} mb={1} textAlign="center">
              <SuiTypography variant="h5" fontWeight="medium" color="dark">
                Login with
              </SuiTypography>
            </SuiBox>
            <SuiBox display='flex' justifyContent='space-evenly'>
              <FacebookLogin sx={{ backgroundColor: theme ? '#495057' : '#e9ecef' }} />
              <GoogleLogin sx={{ backgroundColor: theme ? '#495057' : '#e9ecef' }} />
            </SuiBox>
          </SuiBox>
        </SuiBox>
      }
    </DashboardLayout>
  );
}

export default SignIn;
