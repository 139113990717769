import { File, ErrorStore } from "components/BackendService";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import SortIcon from "examples/Icons/SortIcon";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { Table } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { darkThemeValue } from "slices/dakThemeSlice";
import { tokenValue, signOut } from "slices/tokenSlice";
import { setProgressEnd } from "slices/apiProgressSlice";
import { addAlert } from "slices/alertsSlice";

function TableComponent({ usersPage, sortColumn, setSortColumn, searchedFiles, setSearchedFiles, ...rest }) {
    const dispatchRedux = useDispatch()
    const { token } = useSelector(tokenValue)
    const { theme } = useSelector(darkThemeValue)
    const DownloadFile = (clientId, fileId) => {
        File(token, clientId, fileId)
            .then(() => {
                dispatchRedux(setProgressEnd())
                dispatchRedux(addAlert(['File downloaded succesfully', 'success']))
            })
            .catch((error) => {
                dispatchRedux(signOut())
                dispatchRedux(addAlert([`${error}`, 'error']))
                ErrorStore(token, `${error}`, 'error')
            })
    }
    const columns = ['clientId', 'fileId', 'uploaded', 'completed', 'dtcs', 'features']
    const headColumns = ['Client', 'File', 'Uploaded', 'Completed', 'DTCS', 'Features']
    const didMount = useRef(false);
    const sortTable = (index) => {
        if (sortColumn === columns[index]) {
            setSearchedFiles([...searchedFiles].reverse())
        }
        else {
            setSortColumn(columns[index])
        }
    }
    useEffect(() => {
        if (didMount.current) {
            if (columns.indexOf(sortColumn) >= 0) {
                const tempUsersFilesData = [...searchedFiles]
                tempUsersFilesData.sort((a, b) => {
                    if (a[sortColumn] < b[sortColumn]) {
                        return -1;
                    }
                    if (a[sortColumn] > b[sortColumn]) {
                        return 1;
                    }
                    return 0
                }).reverse()
                setSearchedFiles(tempUsersFilesData)
            }
            return
        }
        didMount.current = true
    }, [sortColumn])

    return (
        <Table bordered variant={theme ? 'dark' : ''} style={{ ...rest }}>
            <thead>
                <tr>
                    {headColumns.map((column, columnIndex) =>
                        <th key={column}>
                            <SuiBox display='flex' alignItems='center' justifyContent='center' gap={3}>
                                {column}
                                {['Client', 'File', 'Uploaded', 'Completed'].indexOf(column) >= 0 &&
                                    <SuiBox
                                        onClick={() => sortTable(columnIndex)}
                                        sx={{ cursor: 'pointer' }}>
                                        <SortIcon color={theme ? 'white' : 'black'} />
                                    </SuiBox>
                                }
                            </SuiBox>
                        </th>
                    )
                    }
                </tr>
            </thead>
            <tbody>
                {searchedFiles?.length > 0 && searchedFiles.slice(usersPage * 10, usersPage * 10 + 10).map((file) =>
                    <tr key={file.fileId + file.uploaded}>
                        {columns.map((column) =>
                            <>
                                {Object.keys(file).map((optionKey) => {
                                    if (column === optionKey) {
                                        if (optionKey === 'fileId') {
                                            return (
                                                <td>
                                                    <SuiBox
                                                        width='10rem'
                                                        marginInline='auto'
                                                    >
                                                        <SuiButton onClick={() => {
                                                            DownloadFile(file.clientId, file.fileId)
                                                        }}>{file[optionKey]}</SuiButton>
                                                    </SuiBox>
                                                </td>
                                            )
                                        }
                                        if (optionKey === 'dtcs') {
                                            return (
                                                <td>
                                                    <SuiBox
                                                        display='flex'
                                                        flexWrap='wrap'
                                                        justifyContent='center'
                                                        marginInline='auto'
                                                        width='18rem'
                                                    >
                                                        {file[optionKey].map((feature) =>
                                                            <SuiBox
                                                                bgColor={feature.selected ? 'success' : 'light'}
                                                                p={1}
                                                                m={1}
                                                                width='15rem'
                                                                borderRadius='lg'
                                                                variant={feature.selected ? 'gradient' : 'contained'}
                                                                display='flex'
                                                                justifyContent='center'
                                                            >
                                                                {feature.name}
                                                            </SuiBox>
                                                        )}
                                                    </SuiBox>
                                                </td>
                                            )
                                        }
                                        if (optionKey === 'features') {
                                            return (
                                                <td>
                                                    <SuiBox
                                                        display='flex'
                                                        flexWrap='wrap'
                                                        alignItems='center'
                                                        width='15rem'
                                                        height='100%'
                                                        marginInline='auto'>
                                                        {file[optionKey].map((feature) =>
                                                            <SuiBox
                                                                bgColor={feature.selected ? 'success' : 'light'}
                                                                p={1}
                                                                m={1}
                                                                borderRadius='lg'
                                                                width='100%'
                                                                variant={feature.selected ? 'gradient' : 'contained'}
                                                                textAlign='center'
                                                                sx={{ color: theme && feature.selected && '#343a40' }}>
                                                                {feature.name}
                                                            </SuiBox>
                                                        )}
                                                    </SuiBox>
                                                </td>
                                            )
                                        }
                                        return (
                                            <td>
                                                <SuiTypography sx={{ wordBreak: 'break-all', width: 200 }}>
                                                    {file[optionKey]}
                                                </SuiTypography>
                                            </td>
                                        )
                                    }
                                    return null
                                }
                                )}
                                {!file[column] && <td />}
                            </>
                        )}
                    </tr>
                )}
            </tbody>
        </Table>
    );
}
TableComponent.defaultProps = {
    usersPage: PropTypes.number,
    sortColumn: PropTypes.string,
    setSortColumn: PropTypes.func,
    searchedFiles: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.bool])))]))),
    setSearchedFiles: PropTypes.func
};

TableComponent.propTypes = {
    usersPage: 0,
    sortColumn: '',
    setSortColumn: () => { },
    searchedFiles: [],
    setSearchedFiles: () => { }
};


export default TableComponent;