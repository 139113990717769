import FBIcon from 'examples/Icons/FBIcon';
import { useNavigate } from 'react-router-dom';
import SuiButton from 'components/SuiButton';
import { useDispatch, useSelector } from 'react-redux';
import { setToken, setLoginSource } from 'slices/tokenSlice'
import { addAlert } from 'slices/alertsSlice';
import { meValue } from 'slices/meSlice';

function FacebookLogin({ ...rest }) {
  const dispatchRedux = useDispatch()
  const { token } = useSelector(meValue)
  const Redirect = useNavigate()
  const styles = { 'aspectRatio': '1/1', 'width': 'max-content', 'cursor': 'pointer', 'borderRadius': '100%', 'display': 'flex', 'justifyContent': 'center', 'alignItems': 'center' }

  // window.FB.Event.subscribe('auth.authResponseChange',function(response){
  //   console.log(response)
  // })

  function GetToken() {
    window.FB.getLoginStatus((response) => {
      if (response.status) {
        if (response.status === 'connected' && token !== '') {
          console.log('zalogowany przez FB', response)
        }
        else if (response.status === 'connected' && token === '') {
          dispatchRedux(setToken(response.authResponse.accessToken))
          dispatchRedux(setLoginSource('Facebook'))
          Redirect('/')
        }
        else if (response.status === 'not_authorized') {
          console.log('niezautoryzowany przez FB')
        }
        else {
          console.log('loguje przez FB')
          window.FB.login((res) => {
            if (res.status === 'connected') {
              dispatchRedux(setToken(res.authResponse.accessToken))
              dispatchRedux(setLoginSource('Facebook'))
              Redirect('/')
            }
          }, { scope: 'email,public_profile' })
        }
      }
      else {
        dispatchRedux(addAlert(['Something went wrong', 'error']))
      }
    })
  }

  return (
    <SuiButton onClick={() => GetToken()} {...rest}>
      <div style={styles} role='button' tabIndex={0}>
        <FBIcon size='40px' />
      </div>
    </SuiButton>
  );
}

export default FacebookLogin;
