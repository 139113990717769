import { useNavigate } from 'react-router-dom';
import SuiBox from 'components/SuiBox';
import PropTypes, { oneOfType } from 'prop-types';
import SuiTypography from 'components/SuiTypography';
import { Chip, Grid } from '@mui/material';
import { meValue } from 'slices/meSlice';
import { useSelector } from 'react-redux';
import { tokenValue } from 'slices/tokenSlice';
import { darkThemeValue } from 'slices/dakThemeSlice';
import PayPalButton from '../PayPalButton';

function SubscriptionOption({ offer }) {
    const { token } = useSelector(tokenValue)
    const { active } = useSelector(meValue)
    const { theme } = useSelector(darkThemeValue)
    const Redirect = useNavigate()
    return (
        <Grid item>
                    <SuiBox bgColor='white' width={{ xs: 200, md: 320 }} height={{ xs: 300, md: 500 }} borderRadius='lg' p={{ xs: 2, md: 5 }} display='flex' alignItems='center' justifyContent='space-around' flexDirection='column' gap={{ xs: 2, md: 10 }} variant='gradient' shadow='md'>
                <SuiBox display='flex' alignItems='center' justifyContent='center' flexDirection='column' variant='gradient' bgColor='light' width={{ xs: '60%', md: '75%' }} height='60%' p={{ xs: 0, md: 5 }} borderRadius='lg'>
                    {offer.icon}
                    <SuiTypography textAlign='center' sx={{ userSelect: "none" }}>
                        {offer.itemsToBuy[0].description}
                    </SuiTypography>
                    <SuiTypography variant="caption">{offer.purchased} files</SuiTypography>
                </SuiBox>
                <SuiBox display='flex' flexDirection='column' alignItems='center' gap={1}>
                    <SuiTypography fontWeight='bold'>
                   Yearly Subscription 3000 files 
                    </SuiTypography>
                    <>
                        {active &&
                            <SuiTypography fontWeight='bold'>
                                Price: {offer.itemsToBuy[0].price}€ 
                            </SuiTypography>
                        }
                        {token ?
                            <PayPalButton id={offer.id} type={offer.type} /> :
                            <Chip label='Sign In to buy' variant='outlined' onClick={() => Redirect('/sign-in', { replace: true })} sx={{ fontWeight: 'bold', color: theme ? '#ced4da' : '#212529' }} />
                        }
                    </>
                </SuiBox>
            </SuiBox>
        </Grid>
    );
}

SubscriptionOption.defaultProps = {
    offer: {}
};

SubscriptionOption.propTypes = {
    offer: PropTypes.objectOf(oneOfType([PropTypes.number, PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))]))
};

export default SubscriptionOption;
