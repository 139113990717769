// Soft UI React examples
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { tokenValue, signOut } from "slices/tokenSlice";

function SignOut() {
    const dispatchRedux = useDispatch()
    const Redirect = useNavigate()
    const { token } = useSelector(tokenValue)
    useEffect(() => {
        if (token) {
            dispatchRedux(signOut())
        }
        else {
            Redirect('sign-in', { replace: true })
        }
    })
    return (
        <DashboardLayout>
            <DashboardNavbar />
        </DashboardLayout>
    );
}

export default SignOut;