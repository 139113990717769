// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

function Engine2({ size }) {
    return ( 
        <svg version="1.1" id="Layer_1" x="0px" y="0px"
	 viewBox="0 0 295.238 295.238" style={{"enableBackground":"new 0 0 295.238 295.238"}} height={size} width={size}>
<g>
	<g>
		<g>
			<path d="M257.143,157.143h-14.286v-28.571H220.49l-28.571-19.048h-30.014V80.952H200v-9.524H85.714v9.524h38.095v28.571H93.476
				l-33.333,28.571h-26.81v28.571H9.524V123.81H0v119.048h9.524v-38.095h23.81v38.095h27.305l33.333,19.048h53.648v-9.524H96.505
				l-29.838-17.048v-90.286l30.333-26h92.033l25.252,16.833v16.5h9.524v-14.286h9.524v114.286h-9.524v9.524h19.048v-23.81h14.286
				v9.524h38.095V142.857h-38.095V157.143z M33.334,195.238H9.524V176.19h23.81V195.238z M57.143,233.333H42.857v-85.714h14.286
				V233.333z M152.381,109.523h-19.048V80.952h19.048V109.523z M257.143,228.571h-14.286v-61.905h14.286V228.571z M266.667,152.381
				h19.048v85.714h-19.048V152.381z"/>
			<path d="M192.448,176.19h-35.576l23.81-33.333h-45.533L106.576,200h29.962l-12.833,44.933L192.448,176.19z M121.99,190.476
				l19.048-38.095h21.133l-23.81,33.333h31.09l-26.495,26.495l6.214-21.733H121.99z"/>
			<rect x="38.095" y="28.571" width="28.571" height="9.524"/>
			<rect y="28.571" width="28.571" height="9.524"/>
			<rect x="28.571" y="38.095" width="9.524" height="28.571"/>
			<rect x="28.571" y="0" width="9.524" height="28.571"/>
			<polygon points="276.191,71.429 276.191,52.381 266.667,52.381 266.667,71.429 276.19,71.429 276.19,80.952 266.667,80.952 
				266.667,71.429 247.619,71.429 247.619,80.953 266.667,80.953 266.667,100 276.191,100 276.191,80.953 295.238,80.953 
				295.238,71.429 			"/>
			<rect x="190.476" y="252.381" width="23.81" height="9.524"/>
			<polygon points="180.953,252.381 190.476,252.381 190.476,228.571 180.952,228.571 180.952,252.381 157.143,252.381 
				157.143,261.905 180.952,261.905 180.952,285.715 190.476,285.715 190.476,261.905 180.953,261.905 			"/>
			<polygon points="285.714,266.667 276.19,266.667 276.19,276.19 266.667,276.19 266.667,285.714 276.19,285.714 276.19,295.238 
				285.714,295.238 285.714,285.714 276.191,285.714 276.191,276.191 285.714,276.191 285.714,285.714 295.238,285.714 
				295.238,276.19 285.714,276.19 			"/>
		</g>
	</g>
</g>
</svg>

     );
}

Engine2.defaultProps = {
  size: "20px"
}
Engine2.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}


export default Engine2;