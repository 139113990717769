import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    purchased: 0,
    orderId: ''
}

export const purchaseSlice = createSlice({
    name: 'purchase',
    initialState,
    reducers: {
        setPurchase: (state, action) => {
            Object.assign(state, action.payload)
        },
        clearPurchase: (state) => {
            Object.assign(state, initialState)
        }
    },
})

// Action creators are generated for each case reducer function
export const { setPurchase, clearPurchase } = purchaseSlice.actions
export const purchaseValue = (state) => state.purchase

export default purchaseSlice.reducer