import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    progress: 0,
    isLoading: false
}

export const apiProgressSlice = createSlice({
    name: 'apiProgress',
    initialState,
    reducers: {
        setProgress: (state, action) => {
            if (!state.isLoading)
                state.isLoading = true
            state.progress = action.payload
        },
        setProgressEnd: (state) => {
            state.progress = 0
            state.isLoading = false
        },
        setLoading: (state) => {
            state.isLoading = true
        }

    },
})

// Action creators are generated for each case reducer function
export const { setProgress, setProgressEnd, setLoading } = apiProgressSlice.actions
export const apiProgressValue = (state) => state.apiProgress

export default apiProgressSlice.reducer