import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import UploadIcon from "examples/Icons/Upload";
import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { darkThemeValue } from "slices/dakThemeSlice";
import { meValue } from "slices/meSlice";

function UploadButton({ setSelectedFiles }) {
    const { exceeded } = useSelector(meValue)
    const { theme } = useSelector(darkThemeValue)
    const inputRef = useRef(null)
    const dragRef = useRef(null)
    const onFileChange = (event) => {
        setSelectedFiles(event.target.files)
    };
    const handleDrop = (event) => {
        event.preventDefault()
        setSelectedFiles(event.dataTransfer.files);
    }
    const handleDragOver = () => {
        dragRef.current.style.borderColor = '#6bd446'
        dragRef.current.style.backgroundColor = '#c3ffb9ab'
    }
    const handleDragLeave = () => {
        dragRef.current.style.borderColor = theme ? '#dee2e6' : '#212529'
        dragRef.current.style.backgroundColor = 'transparent'
    }
    useEffect(() => {
        dragRef.current.style.borderColor = theme ? '#dee2e6' : '#212529'
    }, [theme])
    return (
        <SuiBox display='flex' alignItems='center' flexDirection='column' sx={{ width: '100%'}} ref={dragRef}>
        {exceeded ?
            <SuiTypography fontWeight='bold'>Daily limit exceeded</SuiTypography>
            :
            <SuiBox display='flex' justifyContent='center' alignItems='center' flexDirection='column' mt={15} height={100} width={{ xs: '90%', sm: '75%', md: '50%' }} sx={{ border: `2px dashed ${theme ? '#dee2e6' : '#212529'}`, cursor: 'pointer' }} ref={dragRef} onClick={() => inputRef.current.click()}
                onDrop={(event) => {
                    handleDrop(event)
                    handleDragLeave()
                }}
                onDragOver={(event) => {
                    event.preventDefault()
                    handleDragOver()
                }}
                onDragLeave={handleDragLeave}>
                <UploadIcon size={30} marginTop={15} color={theme ? '#dee2e6' : '#212529'} />
                <SuiTypography textTransform='uppercase' fontSize={17} p={2} textAlign='center'>
                    Drop files or click to upload
                </SuiTypography>
                <input type="file" onChange={onFileChange} style={{ display: 'none' }} ref={inputRef} />
            </SuiBox>
        }
        </SuiBox>
    );
}

UploadButton.defaultProps = {
    setSelectedFiles: () => { }
};

UploadButton.propTypes = {
    setSelectedFiles: PropTypes.func
};

export default UploadButton;