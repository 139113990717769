import SuiBox from "components/SuiBox";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAlert } from "slices/alertsSlice";
import { darkThemeValue } from "slices/dakThemeSlice";
import { fileDescriptionValue } from "slices/fileDescriptionSlice";
import { fileFeaturesValue, setFileFeatures } from "slices/fileFeaturesSlice";

function FeatureButton({ children, option, featureIndex }) {
    const dispatchRedux = useDispatch()
    const fileFeatures = useSelector(fileFeaturesValue)
    const { fileId, completed } = useSelector(fileDescriptionValue)
    const { theme } = useSelector(darkThemeValue)
    const [isChecked, setIsChecked] = useState(fileFeatures[option][featureIndex].selected)
    const didMountFeature = useRef(false);
    const changeCheck = () => {
        setIsChecked(prev => !prev)
    }
    useEffect(() => {
        if (fileId) {
            if (!didMountFeature.current) {
                didMountFeature.current = true
            }
            else {
                const tempFeatures = JSON.parse(JSON.stringify(fileFeatures))
                tempFeatures[option][featureIndex].selected = isChecked
                dispatchRedux(setFileFeatures(tempFeatures))
            }
        }
    }, [isChecked])
    return (
        <SuiBox
            bgColor={fileFeatures[option][featureIndex].selected ? 'success' : 'light'}
            display="flex"
            textAlign="center"
            alignItems="center"
            justifyContent="center"
            fontSize={18}
            p={1}
            width={250}
            variant="gradient"
            borderRadius="lg"
            shadow="md"
            onClick={completed ? () => {
                dispatchRedux(addAlert(['You can\'t select more features after downloading', 'warning']))
            } : changeCheck}
            sx={{
                cursor: "pointer", userSelect: "none", wordBreak: 'break-word', outline: fileFeatures[option][featureIndex].selected ? '2px solid gray' : '', color: theme && fileFeatures[option][featureIndex].selected && '#343a40'
            }}
        >
            {children}
        </SuiBox>
    );
}

FeatureButton.defaultProps = {
    children: null,
    option: '',
    featureIndex: -1
};

FeatureButton.propTypes = {
    children: PropTypes.node,
    option: PropTypes.string,
    featureIndex: PropTypes.number
};

export default FeatureButton;
