import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isOpen: false,
    alerts: []
}

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        addAlert: (state, action) => {
            state.alerts.push({ message: action.payload[0], severity: action.payload[1] })
            state.isOpen = true
        },
        closeAlert: (state) => {
            state.isOpen = false
        },
        clearAlerts: (state) => {
            state.alerts = []
        }
    },
})

// Action creators are generated for each case reducer function
export const { addAlert, closeAlert, clearAlerts } = alertsSlice.actions
export const alertsValue = (state) => state.alerts

export default alertsSlice.reducer