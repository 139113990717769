import { TabPanel } from "@material-ui/lab";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { fileFeaturesValue } from "slices/fileFeaturesSlice";
import FeatureButton from "../FeatureButton";

function FeaturesPanel({ index, option, searchedFeatures }) {
    const fileFeatures = useSelector(fileFeaturesValue)
    return (
        <TabPanel value={`${index}`} index={index}>
            <SuiBox display='flex' rowGap={6} columnGap={5} pb={2} sx={{ flexWrap: 'wrap', justifyContent: 'space-around' }}>
                {
                    fileFeatures[option]?.length > 0 ?
                        fileFeatures[option]?.map((feature, featureIndex) =>
                        (feature?.feature.toLocaleLowerCase().indexOf(searchedFeatures) >= 0 ?
                            <FeatureButton key={feature.featureId} option={option} featureIndex={featureIndex}>
                                {
                                    ((['$', '^', '~', '%']).indexOf(feature.feature[0])) < 0 ?
                                        feature.feature : feature.feature.slice(1)}
                            </FeatureButton> : null
                        )
                        ) :
                        <SuiTypography>
                            No features to show
                        </SuiTypography>

                }
            </SuiBox>
        </TabPanel>
    );
}

FeaturesPanel.defaultProps = {
    index: 0,
    option: 'dtcs',
    searchedFeatures: 'null'
};

FeaturesPanel.propTypes = {
    index: PropTypes.number,
    option: PropTypes.string,
    searchedFeatures: PropTypes.string
};

export default FeaturesPanel;
