import SuiPagination from "components/SuiPagination";
import SuiTypography from "components/SuiTypography";
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { darkThemeValue } from "slices/dakThemeSlice";

function PaginationTab({ dispatch, usersPage, data }) {
    const { theme } = useSelector(darkThemeValue)
    return (
        <SuiPagination size='large'>
            <SuiPagination
                item
                onClick={() => dispatch({ type: 'decrement' })}
                sx={{ color: theme ? '#dee2e6' : '#343a40' }}>
                -10
            </SuiPagination>
            {[...Array(data.length > 80 ? 9 : Math.ceil(data.length / 10))].map((element, index) => {
                let pagination = usersPage + index - 4
                let isActive = false
                if ((usersPage >= 4 && usersPage <= Math.ceil(data.length / 10) - 5) && index === 4) {
                    isActive = true
                }
                else if (usersPage < 4 || Math.ceil(data.length / 10) < 9) {
                    pagination = index
                    if (index === usersPage) {
                        isActive = true
                    }
                }
                else if (Math.ceil(data.length / 10) >= 9 && usersPage > Math.ceil(data.length / 10) - 5) {
                    pagination = Math.ceil(data.length / 10) - 9 + index
                    if (usersPage === Math.ceil(data.length / 10) - 9 + index) {
                        isActive = true
                    }
                }
                return <SuiPagination
                    item
                    active={isActive}
                    onClick={() => dispatch({ type: 'setPage', page: pagination })}
                    sx={{ color: theme ? '#dee2e6' : '#343a40' }}>
                    <SuiTypography>
                        {pagination}
                    </SuiTypography>
                </SuiPagination>
            }
            )}
            <SuiPagination
                item
                onClick={() => dispatch({ type: 'increment' })}
                sx={{ color: theme ? '#dee2e6' : '#343a40' }}>
                +10
            </SuiPagination>
        </SuiPagination>
    );
}
PaginationTab.defaultProps = {
    usersPage: PropTypes.number,
    dispatch: PropTypes.func,
    data: PropTypes.arrayOf(
        PropTypes.objectOf(
            PropTypes.oneOfType(
                [PropTypes.string,
                PropTypes.number,
                PropTypes.arrayOf(PropTypes.objectOf(
                    PropTypes.oneOfType([PropTypes.string, PropTypes.bool]
                    ))),
                PropTypes.bool
                ]
            )
        )
    )
};

PaginationTab.propTypes = {
    usersPage: 0,
    dispatch: () => { },
    data: []
};


export default PaginationTab;