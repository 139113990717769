import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        setClients: (state, action) => {
            state.length = 0
            state.push(...action.payload)
        },
        clearClients: (state) => {
            state.length = 0
        }
    },
})

// Action creators are generated for each case reducer function
export const { setClients, clearClients } = clientsSlice.actions
export const clientsValue = (state) => state.clients

export default clientsSlice.reducer