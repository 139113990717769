import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useSelector } from "react-redux";
import { darkThemeValue } from "slices/dakThemeSlice";
import { meValue } from "slices/meSlice";

function Instructions() {
  const {theme} = useSelector(darkThemeValue)
  const {active} = useSelector(meValue)
  const instructionsData = [
    {
      title:
        'Step by step instruction',
      steps: [
        'press shift + f5',
        'upload file',
        'choose options by clicking on any from available',
        'press PayPal buton and proceed with payment',
        'after payment DOWNLOAD button will become unlocked and you can press it',
        'Unzip file before write to ecu'
      ]
    },
    {
      title:
        <SuiBox>
          Example by
          <a href='https://www.youtube.com/watch?v=3V38Dtvvqmc'> video</a>
        </SuiBox>,
      steps: [
        'This is how you can use JARVIS to tune or remove addons like DPF EGR for Simens/Continental PCR2.1 in Vw Golf 6. Ace Tuning files database contain now 176 340 files with many variants.',
        'When you upload file this is YOUR ACTUAL FILE that you geting back. Behind What you see on video Jarvis analyzed your file, compared to all our database and selected for you changes that are possible to apply.',
        'What’s offered to you is dyno tested solution of tuning and tested solution of removal adding. If you will get no options available most likely file been modified in past or simply wasn’t updated by one of our operators yet then send file to us and retry Jarvis within 15mins.'
      ]
    }
  ]
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiTypography variant="h1" p={5} textAlign="center" fontWeight="bold">
        Instructions
      </SuiTypography>
      {instructionsData.map((data) =>
        <Accordion sx={{ backgroundColor: theme ? '#343a40' : 'white' }} disabled={!active}>
          <AccordionSummary>
            <SuiTypography>
              {data.title}
            </SuiTypography>
          </AccordionSummary>
          <AccordionDetails>
            <ul style={{ listStyle: 'none', display: 'flex', flexDirection: 'column', gap: '1em' }}>
              {[...data.steps].map((step, index) =>
                <li>
                  <SuiTypography>
                    <span style={{ marginRight: '1em', fontWeight: 'bold', fontSize: '1.2em' }}>{index + 1}</span>
                    {step}
                  </SuiTypography>
                </li>
              )}
            </ul>
          </AccordionDetails>
        </Accordion>
      )}
    </DashboardLayout >
  );
}

export default Instructions;