// Soft UI Dashboard React examples
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useSelector } from "react-redux";
import { darkThemeValue } from "slices/dakThemeSlice";

function Privacy() {
  const { theme } = useSelector(darkThemeValue);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiTypography variant="h1" p={5} textAlign="center" fontWeight="bold">
        Privacy Policy
      </SuiTypography>
      <Accordion sx={{ backgroundColor: theme ? "#343a40" : "white" }}>
        <AccordionSummary>
          <SuiTypography>
            Introduction
          </SuiTypography>
        </AccordionSummary>
        <AccordionDetails>
          <SuiTypography>
            Welcome to Tuning File Online, the Jarvis application. We value your privacy and are committed
            to protecting your personal information. This Privacy Policy explains how we collect, use,
            disclose, and safeguard your information when you use our application.
          </SuiTypography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: theme ? "#343a40" : "white" }}>
        <AccordionSummary>
          <SuiTypography>
            Information We Collect: Personal Information
          </SuiTypography>
        </AccordionSummary>
        <AccordionDetails>
          <SuiTypography>
            When you use Tuning File Online, we may collect the following personal information:
            <ul>
              <li>**Contact Information:** Name, email address, phone number.</li>
              <li>**Account Information:** Username, password, and other account-related information.</li>
              <li>**Payment Information:** Credit card details and billing address.</li>
              <li>**Usage Information:** Information about how you use our application, including your IP address,
                browser type, access times, and pages viewed.
              </li>
            </ul>
          </SuiTypography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: theme ? "#343a40" : "white" }}>
        <AccordionSummary>
          <SuiTypography>
            Information We Collect: Non-Personal Information
          </SuiTypography>
        </AccordionSummary>
        <AccordionDetails>
          <SuiTypography>
            We may also collect non-personal information, which cannot be used to identify you individually. This
            includes:
            <ul>
              <li>**Device Information:** Information about your device, including hardware model, operating system, and
                device identifiers.
              </li>
              <li>**Analytics Data:** Information collected through cookies and similar technologies to analyze usage
                patterns and improve our services.
              </li>
            </ul>
          </SuiTypography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: theme ? "#343a40" : "white" }}>
        <AccordionSummary>
          <SuiTypography>
            How We Use Your Information
          </SuiTypography>
        </AccordionSummary>
        <AccordionDetails>
          <SuiTypography>
            We use the information we collect for various purposes, including:
            <ul>
              <li>**To Provide and Maintain Services:** To operate and provide the features and functionality of the
                application.
              </li>
              <li>**To Process Transactions:** To process your payments and manage your orders.</li>
              <li>**To Improve Services:** To understand and analyze how you use our application and improve our
                offerings.
              </li>
              <li>**To Communicate with You:** To send you updates, newsletters, and other information about our
                services.
              </li>
              <li>**To Enforce Policies:** To enforce our terms, conditions, and policies.</li>
              <li>**To Comply with Legal Obligations:** To comply with applicable laws and regulations.</li>
            </ul>
          </SuiTypography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: theme ? "#343a40" : "white" }}>
        <AccordionSummary>
          <SuiTypography>
            Sharing Your Information
          </SuiTypography>
        </AccordionSummary>
        <AccordionDetails>
          <SuiTypography>
            We may share your information with third parties in the following circumstances:
            <ul>
              <li>**Service Providers:** We may share your information with third-party service providers who perform
                services on our behalf, such as payment processing, data analysis, and customer support.
              </li>
              <li>**Legal Requirements:** We may disclose your information if required to do so by law or in response to
                valid requests by public authorities.
              </li>
              <li>**Business Transfers:** We may share or transfer your information in connection with, or during
                negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of
                our
                business to another company.
              </li>
              <li>**With Your Consent:** We may share your information with third parties when we have your consent to
                do
                so.
              </li>
            </ul>
          </SuiTypography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: theme ? "#343a40" : "white" }}>
        <AccordionSummary>
          <SuiTypography>
            Security of Your Information
          </SuiTypography>
        </AccordionSummary>
        <AccordionDetails>
          <SuiTypography>
            We take reasonable measures to protect your personal information from unauthorised access, use, or
            disclosure. However, no method of transmission over the internet or electronic storage is 100% secure.
            Therefore, we cannot guarantee its absolute security.
          </SuiTypography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: theme ? "#343a40" : "white" }}>
        <AccordionSummary>
          <SuiTypography>
            Your Choices
          </SuiTypography>
        </AccordionSummary>
        <AccordionDetails>
          <SuiTypography>
            You have several choices regarding the use of your information:
            <ul>
              <li>**Access and Update:** You can access and update your personal information through your account
                settings.
              </li>
              <li>**Opt-Out:** You can opt-out of receiving promotional emails from us by following the unsubscribe
                instructions in those emails.
              </li>
              <li>**Delete Account:** You can request the deletion of your account by contacting our support team.</li>
            </ul>
          </SuiTypography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: theme ? "#343a40" : "white" }}>
        <AccordionSummary>
          <SuiTypography>
            Changes to This Privacy Policy
          </SuiTypography>
        </AccordionSummary>
        <AccordionDetails>
          <SuiTypography>
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new
            Privacy Policy on our application. You are advised to review this Privacy Policy periodically for any
            changes.
          </SuiTypography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: theme ? "#343a40" : "white" }}>
        <AccordionSummary>
          <SuiTypography>
            Contact Us
          </SuiTypography>
        </AccordionSummary>
        <AccordionDetails>
          <SuiTypography>
            If you have any questions about this Privacy Policy, please contact us at: [autoplik@gmail.com]
            By using Tuning File Online, you acknowledge that you have read and understood this Privacy Policy and agree
            to its terms.
          </SuiTypography>
        </AccordionDetails>
      </Accordion>
    </DashboardLayout>
  );
}

export default Privacy;