/**
=========================================================
* Soft UI Dashboard React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from 'react';

// react-router-dom components
import { useLocation, NavLink } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';

// Soft UI Dashboard React components
import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
// import SuiButton from 'components/SuiButton';

// Soft UI Dashboard React examples
import SidenavCollapse from 'examples/Sidenav/SidenavCollapse';
// import SidenavCard from 'examples/Sidenav/SidenavCard';

// Custom styles for the Sidenav
import SidenavRoot from 'examples/Sidenav/SidenavRoot';
import sidenavLogoLabel from 'examples/Sidenav/styles/sidenav';

// Soft UI Dashboard React context
import { useSoftUIController, setMiniSidenav } from 'context';
import { useSelector } from 'react-redux';
import { tokenValue } from 'slices/tokenSlice';
import { meValue } from 'slices/meSlice';

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const { token } = useSelector(tokenValue)
  const { admin } = useSelector(meValue)
  const layoutsForSignedOut = ['billing', 'faq', 'sign-in', 'account',  'privacy'];
  const layoutsForSignedIn = ['uploads', 'gains', 'aituner',  'billing', 'ticketsystem',   'sign-out', 'account',  'checkpowergains','request', 'privacy']
  const layoutsForAdmins = ['admin', 'report', 'clients', 'scripts']

  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split('/').slice(1)[0];

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener('resize', handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, route, href }) => {

    const isVisible =
      (token && layoutsForSignedIn.includes(key))
      || (!token && layoutsForSignedOut.includes(key))
      || (admin && layoutsForAdmins.includes(key))


    let returnValue;
    if (isVisible) {
      if (type === 'collapse') {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target='_blank'
            rel='noreferrer'
            sx={{ textDecoration: 'none' }}
          >
            <SidenavCollapse
              color={color}
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
              style={{
               boxShadow: "0px 4px 10px rgba(138, 43, 226, 0.3)"
                    }}
            />
          </Link>
        ) : (
          <NavLink to={route} key={key}>
            <SidenavCollapse
              color={color}
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              noCollapse={noCollapse}
              style={{
               boxShadow: "0px 4px 10px rgba(138, 43, 226, 0.3)"
                    }}
            />
          </NavLink>

        );
      } else if (type === 'title') {
        returnValue = (
          <SuiTypography
            key={key}
            display='block'
            variant='caption'
            fontWeight='bold'
            textTransform='uppercase'
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </SuiTypography>
        );
      } else if (type === 'divider') {
        returnValue = <Divider key={key} />;
      }
    }

    return returnValue;

  });

  return (
    <SidenavRoot {...rest} variant='permanent' ownerState={{ transparentSidenav, miniSidenav }}>
      <SuiBox pt={3} pb={1} px={4} textAlign='center'>
        <SuiBox
          display={{ xs: 'block', xl: 'none' }}
          position='absolute'
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: 'pointer' }}
        >
          <SuiTypography variant='h6' color='secondary'>
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </SuiTypography>
        </SuiBox>
        <SuiBox component={NavLink} to='/' display='flex' flexDirection='column' alignItems='center'>
          {brand && <SuiBox component='img' pb={1} src={brand} alt='Jarvis - Tuning Files logo' width='9rem' />}
          <SuiBox sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}>
            <SuiTypography component='h6' variant='button' fontWeight='medium'>
              {brandName}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
      </SuiBox>
      <Divider />
      <List sx={{ paddingBottom: '30px' }}>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: 'info',
  brand: '',
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
