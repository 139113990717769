import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ErrorStore, CreateOrder, CaptureOrder, Me } from 'components/BackendService';
import { useDispatch, useSelector } from 'react-redux';
import { tokenValue } from 'slices/tokenSlice';
import { addAlert } from 'slices/alertsSlice';
import { setPurchase } from 'slices/purchaseSlice';
import { setBalance } from "slices/meSlice";

function PayPalButton({ id, type }) {
  const dispatchRedux = useDispatch()
  const { token } = useSelector(tokenValue)
  useEffect(() => {
    window.paypal.Buttons({
      // Configure environment
      env: 'production',
      client: {
        sandbox: 'AY43CjPDI1p_dKOvjYbpQDRo8lhBYi91ktOY8ztIvZyhFbAiWCjOAMne1T7x8np_8fQe_f1KKecQbWoA',
        production: 'AaTDdUh3penOZNhVgAkInKziwY3yKyUUnSjOiobZ3EuulO0n7pYPQocy1sm3tz1W-Bfm--Qjh2Zzf3xm'
      },
      // Customize button (optional)
      locale: 'pl_PL',
      style: {
        layout: 'vertical',
        color:  'silver',
        shape:  'rect',
        label:  'paypal'
      },

      // Enable Pay Now checkout flow (optional)
      commit: true,

      createOrder() {
        return CreateOrder(token, type).then((res) => res.data.orderId)
      },

      onApprove(data) {
        dispatchRedux(addAlert(['Payment executed successfully', 'success']))
        return CaptureOrder(token, data.orderID)
          .then(() => {
            dispatchRedux(setPurchase(data))
            Me(token)
              .then(res => {
                dispatchRedux(setBalance(res.data.balance))
              })
          })
          .catch((error) => {
            dispatchRedux(addAlert([`${error}`, 'error']))
            ErrorStore(token, `${error}`, 'error')
          })
      },

      onCancel(/* data, actions */) {
        dispatchRedux(addAlert(['Payment canceled', 'error']))
        // Show a cancel page or return to cart
      },

      onError(error) {
        dispatchRedux(addAlert([`${error}`, 'error']))
        ErrorStore(token, `${error}`, 'error')
        // Show an error page here, when an error occurs
      }
    }).render(`#${id}`);
  }, [])
  return (
    <div id={id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} />
  );
}

PayPalButton.defaultProps = {
  id: '',
  type: ''
};

PayPalButton.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string
};

export default PayPalButton;
