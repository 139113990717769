// Soft UI Dashboard React examples
import SuiBox from "components/SuiBox";
import { Scripts as ScriptsApi, ErrorStore } from "components/BackendService";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import SuiTypography from "components/SuiTypography";
import UploadButton from "components/UploadButton";
import { useSelector, useDispatch } from "react-redux";
import { tokenValue, signOut } from "slices/tokenSlice";
import { meValue } from "slices/meSlice";
import { setProgressEnd } from "slices/apiProgressSlice";
import { addAlert } from "slices/alertsSlice";

function Scripts() {
    const dispatchRedux = useDispatch()
    const { token } = useSelector(tokenValue)
    const { admin } = useSelector(meValue)
    const [selectedFiles, setSelectedFiles] = useState(null)
    useEffect(() => {
        if (selectedFiles) {
            [...selectedFiles].forEach((file) => {
                const formData = new FormData();
                formData.append(
                    "file",
                    file,
                    file.name
                )
                ScriptsApi(token, formData)
                    .then(() => {
                        dispatchRedux(setProgressEnd())
                        dispatchRedux(addAlert(['Scripts uploaded successfully', 'success']))
                    })
                    .catch((error) => {
                        dispatchRedux(signOut())
                        dispatchRedux(addAlert([`${error}`, 'error']))
                        ErrorStore(token, `${error}`, 'error')
                    })
            })
        }
    }, [selectedFiles])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {admin ?
                <SuiBox display='flex' justifyContent='center' alignItems='center'>
                    <UploadButton setSelectedFiles={setSelectedFiles} />
                </SuiBox> :
                <SuiTypography fontWeight='bold'>
                    You&apos;re not an admin
                </SuiTypography>
            }

        </DashboardLayout >
    );
}

export default Scripts;