import PropTypes from "prop-types";

function Credit10({ size }) {
    return (
        <svg width={size} height={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 496 496" style={{ "enableBackground": "new 0 0 496 496" }} xmlSpace="preserve">
            <g>
                <path d="M335.383,12.126C311.924,4.307,280.891,0,248,0s-63.924,4.307-87.383,12.126C127.047,23.316,120,37.811,120,48v400
		c0,10.189,7.047,24.684,40.617,35.874C184.076,491.693,215.109,496,248,496s63.924-4.307,87.383-12.126
		C368.953,472.684,376,458.189,376,448V48C376,37.811,368.953,23.316,335.383,12.126z M248,16c68.37,0,112,18.952,112,32
		s-43.63,32-112,32S136,61.048,136,48S179.63,16,248,16z M248,480c-68.37,0-112-18.952-112-32v-15.573
		c5.939,3.987,13.93,7.885,24.617,11.447C184.076,451.693,215.109,456,248,456c19.97,0,39.817-1.638,57.396-4.736
		c4.352-0.768,7.257-4.917,6.49-9.268c-0.767-4.352-4.92-7.248-9.267-6.49C285.941,438.446,267.054,440,248,440
		c-68.37,0-112-18.952-112-32v-15.573c5.939,3.987,13.93,7.885,24.617,11.447C184.076,411.693,215.109,416,248,416
		c19.97,0,39.817-1.638,57.396-4.736c4.352-0.768,7.257-4.917,6.49-9.268s-4.92-7.253-9.267-6.49
		C285.941,398.446,267.054,400,248,400c-68.37,0-112-18.952-112-32v-15.573c5.939,3.987,13.93,7.885,24.617,11.447
		C184.076,371.693,215.109,376,248,376c19.97,0,39.817-1.638,57.396-4.736c4.352-0.768,7.257-4.917,6.49-9.268
		c-0.767-4.352-4.92-7.248-9.267-6.49C285.941,358.446,267.054,360,248,360c-68.37,0-112-18.952-112-32v-15.573
		c5.939,3.987,13.93,7.885,24.617,11.447C184.076,331.693,215.109,336,248,336c19.97,0,39.817-1.638,57.396-4.736
		c4.352-0.768,7.257-4.917,6.49-9.268c-0.767-4.352-4.92-7.253-9.267-6.49C285.941,318.446,267.054,320,248,320
		c-68.37,0-112-18.952-112-32v-15.573c5.939,3.987,13.93,7.885,24.617,11.447C184.076,291.693,215.109,296,248,296
		c19.97,0,39.817-1.638,57.396-4.736c4.352-0.768,7.257-4.917,6.49-9.268c-0.767-4.352-4.92-7.248-9.267-6.49
		C285.941,278.446,267.054,280,248,280c-68.37,0-112-18.952-112-32v-15.573c5.939,3.987,13.93,7.885,24.617,11.447
		C184.076,251.693,215.109,256,248,256c19.97,0,39.817-1.638,57.396-4.736c4.352-0.768,7.257-4.917,6.49-9.268
		s-4.92-7.252-9.267-6.49C285.941,238.446,267.054,240,248,240c-68.37,0-112-18.952-112-32v-15.573
		c5.939,3.987,13.93,7.885,24.617,11.447C184.076,211.693,215.109,216,248,216c19.97,0,39.817-1.638,57.396-4.736
		c4.352-0.768,7.257-4.917,6.49-9.268c-0.767-4.352-4.92-7.248-9.267-6.49C285.941,198.446,267.054,200,248,200
		c-68.37,0-112-18.952-112-32v-15.573c5.939,3.987,13.93,7.885,24.617,11.447C184.076,171.693,215.109,176,248,176
		c19.97,0,39.817-1.638,57.396-4.736c4.352-0.768,7.257-4.917,6.49-9.268c-0.767-4.352-4.92-7.252-9.267-6.49
		C285.941,158.446,267.054,160,248,160c-68.37,0-112-18.952-112-32v-15.573c5.939,3.987,13.93,7.885,24.617,11.447
		C184.076,131.693,215.109,136,248,136c19.97,0,39.817-1.638,57.396-4.736c4.352-0.768,7.257-4.917,6.49-9.268
		c-0.767-4.352-4.92-7.247-9.267-6.49C285.941,118.446,267.054,120,248,120c-68.37,0-112-18.952-112-32V72.427
		c5.939,3.987,13.93,7.885,24.617,11.447C184.076,91.693,215.109,96,248,96s63.924-4.307,87.383-12.126
		c10.688-3.563,18.678-7.46,24.617-11.447V88c0,7.432-14.386,15.238-26.705,19.666c-4.158,1.494-6.317,6.076-4.823,10.234
		c1.173,3.264,4.249,5.296,7.529,5.296c0.898,0,1.812-0.152,2.705-0.474c9.051-3.253,15.989-6.751,21.293-10.316V128
		c0,7.432-14.386,15.238-26.705,19.666c-4.158,1.494-6.317,6.076-4.823,10.234c1.173,3.264,4.249,5.296,7.529,5.296
		c0.898,0,1.812-0.152,2.705-0.474c9.051-3.253,15.989-6.751,21.293-10.316V168c0,7.432-14.386,15.238-26.705,19.666
		c-4.158,1.494-6.317,6.076-4.823,10.234c1.173,3.264,4.249,5.296,7.529,5.296c0.898,0,1.812-0.152,2.705-0.474
		c9.051-3.253,15.989-6.751,21.293-10.316V208c0,7.432-14.386,15.238-26.705,19.666c-4.158,1.494-6.317,6.076-4.823,10.234
		c1.173,3.264,4.249,5.296,7.529,5.296c0.898,0,1.812-0.152,2.705-0.474c9.051-3.253,15.989-6.751,21.293-10.316V248
		c0,7.432-14.386,15.238-26.705,19.666c-4.158,1.494-6.317,6.076-4.823,10.234c1.173,3.264,4.249,5.296,7.529,5.296
		c0.898,0,1.812-0.152,2.705-0.474c9.051-3.253,15.989-6.751,21.293-10.316V288c0,7.432-14.386,15.238-26.705,19.666
		c-4.158,1.494-6.317,6.076-4.823,10.234c1.173,3.264,4.249,5.296,7.529,5.296c0.898,0,1.812-0.152,2.705-0.474
		c9.051-3.253,15.989-6.751,21.293-10.316V328c0,7.432-14.386,15.238-26.705,19.666c-4.158,1.494-6.317,6.076-4.823,10.234
		c1.173,3.264,4.249,5.296,7.529,5.296c0.898,0,1.812-0.152,2.705-0.474c9.051-3.253,15.989-6.751,21.293-10.316V368
		c0,7.432-14.386,15.238-26.705,19.666c-4.158,1.494-6.317,6.076-4.823,10.234c1.173,3.264,4.249,5.296,7.529,5.296
		c0.898,0,1.812-0.152,2.705-0.474c9.051-3.253,15.989-6.751,21.293-10.316V408c0,7.432-14.386,15.238-26.705,19.666
		c-4.158,1.494-6.317,6.076-4.823,10.234c1.173,3.264,4.249,5.296,7.529,5.296c0.898,0,1.812-0.152,2.705-0.474
		c9.051-3.253,15.989-6.751,21.293-10.316V448C360,461.048,316.37,480,248,480z"/>
                <path d="M172.948,62.378c0.997,0.41,2.028,0.604,3.043,0.604c3.145,0,6.128-1.867,7.4-4.956c1.547-3.758,0.023-8.016-3.411-9.983
		c3.38-1.291,8.983-2.917,18.074-4.437C211.973,41.281,229.71,40,248,40c13.763,0,27.278,0.722,39.084,2.088
		c4.398,0.512,8.359-2.639,8.866-7.028c0.508-4.389-2.639-8.358-7.027-8.866C276.517,24.759,262.366,24,248,24
		c-21.88,0-42.527,1.72-58.137,4.842C175.959,31.622,160,36.313,160,48C160,54.002,164.356,58.84,172.948,62.378z"/>
                <path d="M248,56c-13.762,0-27.276-0.722-39.081-2.088c-4.398-0.503-8.359,2.64-8.866,7.028c-0.508,4.389,2.638,8.358,7.027,8.866
		C219.485,71.241,233.635,72,248,72c21.88,0,42.527-1.72,58.137-4.842C320.041,64.378,336,59.688,336,48
		c0-6.002-4.356-10.84-12.948-14.378c-4.085-1.682-8.761,0.266-10.444,4.352c-1.547,3.758-0.023,8.016,3.411,9.983
		c-3.38,1.291-8.983,2.917-18.074,4.437C284.027,54.719,266.29,56,248,56z"/>
            </g>
        </svg>

    );
}

Credit10.defaultProps = {
    size: "20px"
}
Credit10.propTypes = {
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}


export default Credit10;