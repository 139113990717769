import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    fileId: '',
    fileName: '',
    uploaded: '',
    completed: ''
}

export const fileDescriptionSlice = createSlice({
    name: 'fileDescription',
    initialState,
    reducers: {
        setFileDescription: (state, action) => {
            Object.keys(state).forEach(key => {
                if (action.payload[key]) {
                    state[key] = action.payload[key]
                }
                if(!action.payload.completed){
                    state.completed = false
                }
            })
        },
        clearFileDescription: (state) => {
            Object.assign(state, initialState)
        },
        setComplete: (state, action) => {
            state.completed = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setFileDescription, clearFileDescription, setComplete } = fileDescriptionSlice.actions
export const fileDescriptionValue = (state) => state.fileDescription

export default fileDescriptionSlice.reducer