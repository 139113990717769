import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    token: '',
    loginSource: ''
}

export const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
        },
        setLoginSource: (state, action) => {
            state.loginSource = action.payload
        },
        signOut: (state) => {
            const auth2 = window.gapi.auth2.getAuthInstance();
            if (auth2.isSignedIn.get() === true) {
                console.log("wylogowuje z Google")
                auth2.signOut()
            }
            else {
                window.FB.getLoginStatus((res) => {
                    if (res.status === "connected") {
                        window.FB.logout(() => {
                            console.log("wylogowuje z FB")
                        })
                    }
                })
            }
            state.loginSource = 'None'
            state.token = ''
        }
    },
})

// Action creators are generated for each case reducer function
export const { setToken, setLoginSource, signOut } = tokenSlice.actions
export const tokenValue = (state) => state.token

export default tokenSlice.reducer