import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';
import PropTypes from 'prop-types';

function DownloadButton({ text, DownloadFunction }) {
    return (
        <SuiButton size='medium' onClick={DownloadFunction} sx={{ marginTop: 5 }}>
            <SuiTypography fontWeight='bold'>
                {text}
            </SuiTypography>
        </SuiButton >
    );
}

DownloadButton.defaultProps = {
    text: '',
    DownloadFunction: () => { }
};

DownloadButton.propTypes = {
    text: PropTypes.string,
    DownloadFunction: PropTypes.func
};

export default DownloadButton;
