import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    admin: false,
    active: false,
    balance: 0,
    subscription: false,
    canSubscribe: false,
    amount: 30,
    amountSubscription: 150,
    exceeded: false
}

export const meSlice = createSlice({
    name: 'me',
    initialState,
    reducers: {
        setMe: (state, action) => {
            Object.assign(state, action.payload)
        },
        clearMe: (state) => {
            Object.assign(state, initialState)
        },
        setBalance: (state, action) => {
            state.balance = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setMe, clearMe, setBalance } = meSlice.actions
export const meValue = (state) => state.me

export default meSlice.reducer