import SuiBox from 'components/SuiBox';
import SuiTypography from 'components/SuiTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { tokenValue } from 'slices/tokenSlice';

function ManualTuner() {
  const Redirect = useNavigate()
  const { token } = useSelector(tokenValue)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {token ?
        <SuiBox display='flex' justifyContent='center' alignItems='center' mt={30} fontSize={30}>
          Coming Soon
        </SuiBox> :
        <SuiTypography fontWeight='bold' sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => Redirect('/sign-in')} >Sign in here</SuiTypography>
      }
    </DashboardLayout>
  );
}

export default ManualTuner;