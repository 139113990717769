import PropTypes from "prop-types";

function Credit1({ size, color }) {
    return (
        <svg width={size} height={size} fill={color} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style={{ "enableBackground": "new 0 0 512 512" }} xmlSpace="preserve">
            <g>
                <g>
                    <path d="M256,62.061c-125.673,0-256,32.97-256,100.849v186.182c0,67.879,130.327,100.848,256,100.848s256-32.97,256-100.848
			V162.909C512,95.03,381.673,62.061,256,62.061z M465.455,349.091c0,22.885-100.848,54.303-209.455,54.303
			S46.545,371.976,46.545,349.091v-32.075C95.646,343.729,176.742,356.849,256,356.849s160.354-13.12,209.455-39.832V349.091z
			 M465.455,256c0,22.885-100.848,54.303-209.455,54.303S46.545,278.885,46.545,256v-32.074
			C95.646,250.638,176.742,263.758,256,263.758s160.354-13.12,209.455-39.832V256z M256,217.212
			c-108.606,0-209.455-31.418-209.455-54.303c0-22.885,100.848-54.303,209.455-54.303s209.455,31.418,209.455,54.303
			C465.455,185.794,364.606,217.212,256,217.212z"/>
                </g>
            </g>
        </svg>

    );
}

Credit1.defaultProps = {
    size: "20px",
    color: 'black'
}
Credit1.propTypes = {
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.string
}


export default Credit1;