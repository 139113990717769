// Soft UI Dashboard React examples
import { Divider, Grid } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Credit1 from 'examples/Icons/Credit1';
import Credit10 from 'examples/Icons/Credit10';
import Credit20 from 'examples/Icons/Credit20';
import SuiTypography from 'components/SuiTypography';
import Clock from 'examples/Icons/Clock';
import Calendar from 'examples/Icons/Calendar';
import { useSelector } from 'react-redux';
import { meValue } from 'slices/meSlice';
import CreditOption from './components/CreditOption';
import SubscriptionOption from './components/SubscriptionOption'

function Billing() {
  const { amount, canSubscribe,amountSubscription } = useSelector(meValue)
  const creditsOffer = [
    {
      id: 'credit1',
      icon: <Credit1 size={25} />,
      type: 'bulk-1',
      purchased: 1,
      itemsToBuy: [
        {
          name: '1 Credit',
          description: '1 Credit',
          type: 'bulk-1',
          quantity: '1',
          price: `${amount}`,
          tax: '0',
          currency: 'EUR'
        }
      ]
    },
    {
      id: 'credit10',
      icon: <Credit10 size={40} />,
      type: 'bulk-10',
      purchased: 10,
      itemsToBuy: [
        {
          name: '10 Credits',
          description: '10 Credits',
          type: 'bulk-10',
          quantity: '1',
          price: '250',
          tax: '0',
          currency: 'EUR'
        }
      ]
    },
    {
      id: 'credit20',
      icon: <Credit20 size={40} />,
      type: 'bulk-20',
      purchased: 20,
      itemsToBuy: [
        {
          name: '20 Credits',
          description: '20 Credits',
          quantity: '1',
          price: '400',
          tax: '0',
          currency: 'EUR'
        }
      ]
    }
  ]
  const monthlySubscriptionsOffer = [
    {
      id: 'subscription300',
      icon: <Clock size={40} />,
      type: 'subscription-monthly',
      purchased: 300,
      itemsToBuy: [
        {
          name: '1 Month Subscription',
          description: '1 Month',
          quantity: '1',
          price: `${amountSubscription}`,
          tax: '0',
          currency: 'EUR'
        }
      ]
    }]
  const subscriptionsOffer = [
    {
      id: 'subscription3000',
      icon: <Calendar size={40} />,
      type: 'subscription-yearly',
      purchased: 3000,
      itemsToBuy: [
        {
          name: '1 Year Subcription',
          description: '1 Year',
          quantity: '1',
          price: '2500',
          tax: '0',
          currency: 'EUR'
        }
      ]
    }
  ]
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiTypography variant="h4" textAlign='center' mt={5}>Credits</SuiTypography>
      <Grid container gap={{ xs: 5, md: 15 }} p={10} alignItems='center' justifyContent='center'>
        {creditsOffer.map((offer) =>
          <CreditOption offer={offer} key={offer.id} />
        )}
      </Grid>
          <Divider />
          <SuiTypography variant="h4" textAlign='center'>Subscriptions</SuiTypography>
          <Grid container gap={{ xs: 5, md: 15 }} p={10} alignItems='center' justifyContent='center'>
            {subscriptionsOffer.map((offer) =>
              <SubscriptionOption offer={offer} key={offer.id} />
            )}
            {monthlySubscriptionsOffer.filter(() => canSubscribe).map((offer) =>
                <SubscriptionOption offer={offer} key={offer.id} />
            )}
          </Grid>
    </DashboardLayout >
  );
}

export default Billing;
