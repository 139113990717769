import SuiButton from "components/SuiButton";
import PropTypes from 'prop-types';
import { Report, ErrorStore } from "components/BackendService";
import { useSelector, useDispatch } from "react-redux";
import { tokenValue, signOut } from "slices/tokenSlice";
import { setReport } from "slices/reportSlice";
import { setProgressEnd, setLoading } from "slices/apiProgressSlice";
import { addAlert } from "slices/alertsSlice";

function RefreshButton({ setSortColumn, ...rest }) {
    const dispatchRedux = useDispatch()
    const { token } = useSelector(tokenValue)
    return (
        <SuiButton onClick={() => {
            dispatchRedux(setLoading())
            setSortColumn('')
            Report(token)
                .then(res => {
                    dispatchRedux(setReport(res.data.solutions))
                })
                .then(() => {
                    dispatchRedux(setProgressEnd())
                    dispatchRedux(addAlert(['File refreshed successfully', 'success']))
                })
                .catch((error) => {
                    dispatchRedux(signOut())
                    dispatchRedux(addAlert([`${error}`, 'error']))
                    ErrorStore(token, `${error}`, 'error')
                })
        }} sx={{ ...rest }}>Refresh</SuiButton>
    );
}
RefreshButton.defaultProps = {
    setSortColumn: PropTypes.func
};

RefreshButton.propTypes = {
    setSortColumn: () => { }
};

export default RefreshButton;