import PropTypes from "prop-types";

function Calendar({ size, color }) {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width={size} height={size} viewBox="0 0 386.416 386.417" style={{ enableBackground: "new 0 0 386.416 386.417" }}
            xmlSpace="preserve" fill={color}>
            <g>
                <g>
                    <g>
                        <path d="M366.416,56.795h-28.438V27.62c0-11.046-8.952-20-20-20h-13c-11.046,0-20,8.954-20,20v29.177h-183.54V27.62
				c0-11.046-8.954-20-20-20h-13c-11.046,0-20,8.954-20,20v29.177H20c-11.045,0-20,8.954-20,20v282c0,11.046,8.955,20,20,20h346.416
				c11.046,0,20-8.954,20-20v-282C386.416,65.75,377.462,56.795,366.416,56.795z M347.416,339.795H39v-200.59h308.416V339.795
				L347.416,339.795z"/>
                        <path d="M76.459,224.956h33.498c8.271,0,15-6.729,15-15v-33.496c0-8.271-6.729-15-15-15H76.459c-8.271,0-15,6.729-15,15v33.496
				C61.459,218.227,68.187,224.956,76.459,224.956z"/>
                        <path d="M176.459,224.956h33.498c8.271,0,15-6.729,15-15v-33.496c0-8.271-6.729-15-15-15h-33.498c-8.271,0-15,6.729-15,15v33.496
				C161.459,218.227,168.187,224.956,176.459,224.956z"/>
                        <path d="M276.459,224.956h33.498c8.271,0,15-6.729,15-15v-33.496c0-8.271-6.729-15-15-15h-33.498c-8.271,0-15,6.729-15,15v33.496
				C261.459,218.227,268.188,224.956,276.459,224.956z"/>
                        <path d="M76.459,314.956h33.498c8.271,0,15-6.729,15-15v-33.496c0-8.271-6.729-15-15-15H76.459c-8.271,0-15,6.729-15,15v33.496
				C61.459,308.227,68.187,314.956,76.459,314.956z"/>
                        <path d="M176.459,314.956h33.498c8.271,0,15-6.729,15-15v-33.496c0-8.271-6.729-15-15-15h-33.498c-8.271,0-15,6.729-15,15v33.496
				C161.459,308.227,168.187,314.956,176.459,314.956z"/>
                        <path d="M276.459,314.956h33.498c8.271,0,15-6.729,15-15v-33.496c0-8.271-6.729-15-15-15h-33.498c-8.271,0-15,6.729-15,15v33.496
				C261.459,308.227,268.188,314.956,276.459,314.956z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}

Calendar.defaultProps = {
    size: "20px",
    color: 'black'
}
Calendar.propTypes = {
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.string
}


export default Calendar;