import PropTypes from "prop-types";

function Clock({ size, color }) {
    return (
        <svg width={size} height={size} viewBox="0 0 512 512" id="_x30_1" style={{ enableBackground: "new 0 0 512 512" }} version="1.1" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill={color}><path d="M0,256c0,141.385,114.615,256,256,256s256-114.615,256-256S397.385,0,256,0S0,114.615,0,256z M226,90  c0-16.569,13.431-30,30-30s30,13.431,30,30v148.679l76.799,44.34c14.349,8.284,19.265,26.632,10.981,40.981h0  c-8.284,14.349-26.632,19.265-40.981,10.981l-91.799-53c-9.615-5.552-14.987-15.623-14.996-25.981H226V90z"/></svg>
    );
}

Clock.defaultProps = {
    size: "20px",
    color: 'black'
}
Clock.propTypes = {
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.string
}


export default Clock;