import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dtcs: [],
    hardcuts: [],
    launches: [],
    popcorns: [],
    solutions: []
}

export const fileFeaturesSlice = createSlice({
    name: 'fileFeatures',
    initialState,
    reducers: {
        setFileFeatures: (state, action) => {
            Object.keys(state).forEach(key => {
                if (action.payload[key]) {
                    state[key] = [...action.payload[key]]
                }
            })
        },
        clearFileFeatures: (state) => {
            Object.assign(state, initialState)
        }
    }
})

// Action creators are generated for each case reducer function
export const { setFileFeatures, clearFileFeatures } = fileFeaturesSlice.actions
export const fileFeaturesValue = (state) => state.fileFeatures

export default fileFeaturesSlice.reducer