// Soft UI Dashboard React examples
import { TabContext } from "@material-ui/lab";
import { FormHelperText, Tab, Tabs } from "@mui/material";
import { DownloadDTC, Download, Features, Me, ErrorStore } from "components/BackendService";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { tokenValue } from "slices/tokenSlice";
import { darkThemeValue } from "slices/dakThemeSlice";
import { meValue, setBalance } from "slices/meSlice";
import { fileDescriptionValue, setComplete } from "slices/fileDescriptionSlice";
import { setProgressEnd } from "slices/apiProgressSlice";
import { addAlert } from "slices/alertsSlice";
import { fileFeaturesValue } from "slices/fileFeaturesSlice";
import DownloadButton from "./components/DownloadButton";
import FeaturesPanel from "./components/FeaturesPanel";

function AITuner() {
  const dispatchRedux = useDispatch()
  const TabOptions = ['SOLUTION', 'DTC', 'HARDCUT', 'LAUNCH CONTROL', 'POPCORN']
  const options = ['solutions', 'dtcs', 'hardcuts', 'launches', 'popcorns']
  const Redirect = useNavigate()
  const { token } = useSelector(tokenValue)
  const { fileId, fileName, completed } = useSelector(fileDescriptionValue)
  const { balance } = useSelector(meValue)
  const { theme } = useSelector(darkThemeValue)
  const fileFeatures = useSelector(fileFeaturesValue)
  const [searchedFeatures, setSearchedFeatures] = useState('')
  const [tabValue, setTabValue] = useState(4)
  const searchFeatures = (e) => {
    setSearchedFeatures(e.target.value.toLowerCase())
  }

  const DownloadDTCFile = () => {
    const dtcFeatures = []
    Object.entries(fileFeatures.dtcs).forEach(feature => {
      if (feature[1].selected) {
        dtcFeatures.push(feature[1])
      }
    })
    if (dtcFeatures.length > 0) {
      if (!completed) {
        Features(token, dtcFeatures)
          .then(
            DownloadDTC(token, fileId)
              .then(() => {
                dispatchRedux(setProgressEnd())
                dispatchRedux(addAlert(['File downloaded successfully', 'success']))
              })
              .catch((error) => {
                dispatchRedux(addAlert([`${error}`, 'error']))
                ErrorStore(token, `${error}`, 'error')
              })
          )
          .catch((error) => {
            dispatchRedux(addAlert([`${error}`, 'error']))
            ErrorStore(token, `${error}`, 'error')
          })
      }
      else {
        DownloadDTC(token, fileId)
          .then(() => {
            dispatchRedux(setProgressEnd())
            dispatchRedux(addAlert(['File downloaded successfully', 'success']))
          })
          .catch((error) => {
            dispatchRedux(addAlert([`${error}`, 'error']))
            ErrorStore(token, `${error}`, 'error')
          })
      }
    }
    else {
      dispatchRedux(addAlert(['No selected features', 'warning']))
    }
  }

  const DownloadFile = () => {
    const features = []
    Object.keys(fileFeatures).forEach(option => {
      if (option !== 'dtcs') {
        Object.entries(fileFeatures[option]).forEach(feature => {
          if (feature[1].selected) {
            features.push(feature[1])
          }
        })
      }
    })
    if (features.length > 0) {
      Object.entries(fileFeatures.dtcs).forEach(feature => {
        if (feature[1].selected) {
          features.push(feature[1])
        }
      })

      if (!completed) {
        dispatchRedux(setComplete(true))
        Features(token, features)
          .then(
            Download(token, fileId)
              .then(() => {
                dispatchRedux(setProgressEnd())
                dispatchRedux(addAlert(['File downloaded succesfully', 'success']))
                Me(token)
                  .then(res => {
                    dispatchRedux(setBalance(res.data.balance))
                  })
              })
              .catch((error) => {
                dispatchRedux(addAlert([`${error}`, 'error']))
                ErrorStore(token, `${error}`, 'error')
              }))
          .catch((error) => {
            dispatchRedux(addAlert([`${error}`, 'error']))
            ErrorStore(token, `${error}`, 'error')
          })
      }
      else {
        Download(token, fileId)
          .then(() => {
            dispatchRedux(setProgressEnd())
            dispatchRedux(addAlert(['File downloaded succesfully', 'success']))
          })
          .catch((error) => {
            dispatchRedux(addAlert([`${error}`, 'error']))
            ErrorStore(token, `${error}`, 'error')
          })
      }
    }
    else {
      dispatchRedux(addAlert(['No selected features', 'warning']))
    }
  }

  const notEnoughtCredits = () => {
    dispatchRedux(addAlert(['Not enough credits', 'error']))
  }
  const handleTabs = (e, value) => {
    setTabValue(value)
  }
  useEffect(() => {
    // disabled automatic selection of popcorn tab if there is one
    // if (fileFeatures.popcorns.length > 0) {
    //   setTabValue(4)
    // }
    setTabValue(0)
  }, [fileFeatures])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {token ?
        <TabContext value={`${tabValue}`}>
          <Tabs value={tabValue} onChange={handleTabs} sx={{ backgroundColor: theme ? '#212529' : 'white' }} orientation={window.innerWidth < 800 ? 'vertical' : 'horizontal'} >
            {TabOptions?.map((option) =>
              <Tab label={option} key={option} />
            )}
          </Tabs>
          <SuiBox shadow="lg" variant="gradient" borderRadius="lg">
            <SuiBox p={3} pt={3} m={2} bgColor='white' display='flex' flexDirection='column' gap={2}>
              {fileId ?
                <>
                  <SuiTypography alignSelf='center' sx={{ overflowWrap: 'anywhere' }}>
                    File: {fileName}
                  </SuiTypography>
                  <SuiInput
                    placeholder="Search feature"
                    icon={{ component: "search", direction: "left" }}
                    onChange={searchFeatures}
                  />
                </> :
                <SuiTypography alignSelf='center'>
                  No uploaded file
                </SuiTypography>
              }
            </SuiBox>
            {fileId ?
              <SuiBox sx={{ overflow: 'scroll', justifyContent: 'center' }} height={400} display='flex'>
                {options.map((option, index) =>
                  Object.keys(fileFeatures).map(key =>
                    key === option &&
                    <FeaturesPanel option={option} index={index} searchedFeatures={searchedFeatures} key={option} />
                  ))}
              </SuiBox> :
              <SuiBox height={100} display='flex' justifyContent='center' alignItems='center'>
                <SuiTypography fontSize={{ xs: 20, md: 30 }} fontWeight='bold' sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => Redirect('/uploads')}>
                  To upload file click here
                </SuiTypography>
              </SuiBox>
            }
          </SuiBox>
          {options[tabValue] === "dtcs" && fileFeatures?.dtcs?.length > 0 && fileId &&
            <DownloadButton DownloadFunction={DownloadDTCFile} text='Download DTC (free)' />
          }
          {options[tabValue] !== 'dtcs' && balance === 0 && fileId &&
            <SuiBox>
              <DownloadButton DownloadFunction={notEnoughtCredits} text='Download' />

              <FormHelperText sx={{ color: theme && '#dee2e6' }}>
                Not enough credits?
              </FormHelperText>
              <FormHelperText sx={{ textDecoration: 'underline', cursor: 'pointer', color: theme && '#dee2e6' }} onClick={() => Redirect('/billing')}>
                Check the offer
              </FormHelperText>
            </SuiBox>
          }
          {options[tabValue] !== 'dtcs' && balance !== 0 && fileId &&
            <DownloadButton DownloadFunction={DownloadFile} text='Download' />
          }
        </TabContext> :
        <SuiTypography fontWeight='bold' sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => Redirect('/sign-in')} >Sign in here</SuiTypography>
      }
    </DashboardLayout >
  );
}

export default AITuner;