import Uploads from "layouts/uploads";
// import Gains from "layouts/gains";
import ManualTuner from "layouts/manualTuner";
import AITuner from "layouts/aiTuner";
import Billing from "layouts/billing";
// import TicketSystem from "layouts/ticketSystem";
import TrainingVideos from "layouts/TrainingVideos";
import FAQ from "layouts/FAQ";
import SignIn from "layouts/sign-in";
import SignOut from "layouts/sign-out";
import Report from "layouts/report";
import Clients from 'layouts/clients'
import Scripts from "layouts/scripts";
import Instructions from "layouts/instructions";

// import Zippers from "examples/Icons/Zippers";
import Engine1 from "examples/Icons/Engine1";
import Engine2 from "examples/Icons/Engine2";
import QuestionMark from "examples/Icons/QuestionMark";
import Monitor from "examples/Icons/Monitor";
import Money from "examples/Icons/Money";
import Upload from "examples/Icons/Upload";
import Person from "examples/Icons/Person";
import Door from "examples/Icons/Door";
// import Support from "examples/Icons/Support"
import ReportIcon from "examples/Icons/ReportIcon";
import ClientIcon from "examples/Icons/Client";
import ScriptsIcon from "examples/Icons/ScriptsIcon";
import Guide from "examples/Icons/Guide";
import Power from "examples/Icons/Power";
import RequestSVG from 'examples/Icons/RequestSVG'
import Privacy from "layouts/privacy";

const routes = [
  // Uploads
  {
    type: "collapse",
    name: "Uploads",
    key: "uploads",
    route: "/uploads",
    icon: <Upload size="20px" />,
    component: <Uploads />,
    noCollapse: true,
  },
  { type: "title", title: "Tuning options", key: "tuning-options" },
  // Gains

  // {
  //   type: "collapse",
  //   name: "Gains",
  //   key: "gains",
  //   route: "/gains",
  //   icon: <Zippers size="22px" />,
  //   component: <Gains />,
  //   noCollapse: true,
  // },

  // Manual Tuner
  {
    type: "collapse",
    name: "Manual Tuner",
    key: "manualtuner",
    route: "/manualtuner",
    icon: <Engine1 size="20px" />,
    component: <ManualTuner />,
    noCollapse: true,
  },

  // AI Tuner
  {
    type: "collapse",
    name: "AI Tuner",
    key: "aituner",
    route: "/aituner",
    icon: <Engine2 size="22px" />,
    component: <AITuner />,
    noCollapse: true,
  },
  { type: "title", title: "Payment", key: "payment" },

  // Billing
  {
    type: "collapse",
    name: "Billing",
    key: "billing",
    route: "/billing",
    icon: <Money size="20px" />,
    component: <Billing />,
    noCollapse: true,
  },
  { type: "title", title: "Assistance", key: "assistance" },

  // Ticket System
  // {
  //   type: "collapse",
  //   name: "Ticket System",
  //   key: "ticketsystem",
  //   route: "/ticketsystem",
  //   icon: <Support size="23px" />,
  //   component: <TicketSystem />,
  //   noCollapse: true,
  // },

  // Instructions
  {
    type: "collapse",
    name: "Check Power Gains",
    key: "checkpowergains",
    href: "https://portal.tuningfile.online/customer/cars",
    icon: <Power size="25px" />,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Request to add/update solution",
    key: "request",
    href: "https://portal.tuningfile.online/customer/fs",
    icon: <RequestSVG size="25px" />,
    noCollapse: true,
  },

  // Instructions
  {
    type: "collapse",
    name: "Instructions",
    key: "instructions",
    route: "/instructions",
    icon: <Guide size="25px" />,
    component: <Instructions />,
    noCollapse: true,
  },

  // Training videos
  {
    type: "collapse",
    name: "Training Videos",
    key: "trainingvideos",
    route: "/trainingvideos",
    icon: <Monitor size="22px" />,
    component: <TrainingVideos />,
    noCollapse: true,
  },

  // FAQ
  {
    type: "collapse",
    name: "FAQ",
    key: "faq",
    route: "/faq",
    icon: <QuestionMark size="23px" />,
    component: <FAQ />,
    noCollapse: true,
  },
  { type: 'title', title: 'Admin', key: 'admin' },

  // Scripts
  {
    type: "collapse",
    name: "Scripts",
    key: "scripts",
    route: "/scripts",
    icon: <ScriptsIcon size="23px" />,
    component: <Scripts />,
    noCollapse: true,
  },

  // Report
  {
    type: "collapse",
    name: "Report",
    key: "report",
    route: "/report",
    icon: <ReportIcon size="23px" />,
    component: <Report />,
    noCollapse: true,
  },

  // Clients
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    route: "/clients",
    icon: <ClientIcon size="23px" />,
    component: <Clients />,
    noCollapse: true,
  },
  { type: "title", title: "Account", key: "account" },

  // Sign in
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/sign-in",
    icon: <Person size="20px" />,
    component: <SignIn />,
    noCollapse: true,
  },

  // Sign out
  {
    type: "collapse",
    name: "Sign Out",
    key: "sign-out",
    route: "/sign-out",
    icon: <Door size="20px" />,
    component: <SignOut />,
    noCollapse: true
  },

  //  Privacy
  {
    type: "collapse",
    name: "Privacy",
    key: "privacy",
    route: "/privacy",
    icon: <QuestionMark size="23px" />,
    component: <Privacy />,
    noCollapse: true,
  },

];

export default routes;
