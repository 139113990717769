import PropTypes from "prop-types";

function Guide({ size, color }) {
    return (
        <svg height={size} width={size} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 67.775 67.775" style={{ "enableBackground": "new 0 0 67.775 67.775" }}
            xmlSpace="preserve" fill={color}>
            <g>
                <path d="M32.853,50.634h4.302c-0.535-0.498-1.025-1.021-1.469-1.577c-0.194-0.246-0.367-0.506-0.542-0.759
		c-0.063-0.094-0.14-0.183-0.198-0.276c-0.231-0.347-0.435-0.7-0.628-1.059l-4.966,1.059V17.932v-0.224l9.888-2.112l11.562-2.469
		v2.469v2.336v7.841c0.393,0.101,0.782,0.216,1.168,0.348c0.395,0.136,0.782,0.285,1.168,0.456c0.296,0.132,0.593,0.266,0.883,0.417
		c0.812,0.43,1.592,0.947,2.335,1.54v-12.94h-3.218v-5.356l-24.961,5.331L3.221,10.238v5.356H0v35.037h23.503v3.504h-4.672v2.336
		h18.688v-2.336h-4.672v-3.501H32.853z M5.561,15.596v-2.469l11.562,2.469l9.886,2.112v0.224v30.089l-21.452-4.58V17.934
		L5.561,15.596L5.561,15.596z M32.173,23.024l15.382-5.081l0.734,2.217l-15.383,5.08L32.173,23.024z M23.456,25.24l-15.385-5.08
		l0.731-2.217l15.384,5.081L23.456,25.24z M23.456,31.503l-15.385-5.08l0.731-2.217l15.384,5.081L23.456,31.503z M8.817,30.466
		l15.375,5.08l-0.731,2.218L8.086,32.683L8.817,30.466z M8.817,36.726l15.375,5.082l-0.731,2.215L8.086,38.943L8.817,36.726z
		 M59.49,47.935l-0.174-0.144l-0.374,0.449l-1.437-1.204c1.656-2.404,2.468-5.26,2.198-8.205c-0.25-2.808-1.455-5.354-3.349-7.379
		c-0.36-0.388-0.702-0.787-1.117-1.13c-0.383-0.321-0.808-0.58-1.218-0.849c-0.292-0.189-0.577-0.377-0.883-0.542
		c-0.379-0.203-0.771-0.378-1.168-0.54c-0.384-0.158-0.771-0.29-1.168-0.408c-1.163-0.348-2.37-0.57-3.593-0.57
		c-3.746,0-7.259,1.649-9.653,4.52c-2.15,2.579-3.167,5.837-2.868,9.176c0.178,1.948,0.835,3.76,1.825,5.384
		c0.219,0.354,0.438,0.712,0.689,1.045c0.2,0.265,0.426,0.511,0.646,0.76c0.072,0.084,0.159,0.161,0.241,0.244
		c0.347,0.369,0.671,0.748,1.063,1.076c0.456,0.384,0.953,0.705,1.457,1.016c1.969,1.216,4.236,1.896,6.567,1.896
		c2.384,0,4.672-0.671,6.638-1.896c0.801-0.498,1.542-1.091,2.222-1.766l0.315,0.262l1.09,0.908l-0.374,0.447l0.174,0.144
		l8.289,6.909l2.245-2.691L59.49,47.935z M54.021,47.504c-0.311,0.282-0.625,0.554-0.961,0.794
		c-1.704,1.202-3.738,1.896-5.878,1.896c-2.104,0-4.138-0.689-5.854-1.896c-0.159-0.114-0.335-0.217-0.492-0.34
		c-0.055-0.051-0.128-0.082-0.188-0.135c-0.306-0.253-0.557-0.555-0.83-0.842c-0.311-0.326-0.598-0.655-0.857-1.016
		c-1.079-1.476-1.772-3.202-1.941-5.068c-0.243-2.719,0.581-5.372,2.329-7.47c1.947-2.338,4.817-3.678,7.86-3.678
		c1.231,0,2.45,0.236,3.593,0.668c0.401,0.152,0.787,0.341,1.168,0.542c0.408,0.214,0.791,0.47,1.168,0.738
		c0.2,0.145,0.417,0.263,0.606,0.423c0.103,0.087,0.178,0.192,0.276,0.279c0.979,0.882,1.77,1.914,2.335,3.065
		c0.543,1.111,0.908,2.311,1.022,3.579c0.171,1.894-0.208,3.736-1.022,5.397c-0.357,0.728-0.775,1.433-1.313,2.078
		C54.738,46.886,54.371,47.182,54.021,47.504z"/>
            </g>
        </svg>
    );
}

Guide.defaultProps = {
    size: "20px",
    color: 'black'
}
Guide.propTypes = {
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.string
}


export default Guide;