// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

function Door({ size, color }) {
  return (
    <svg width={size} height={size} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 491.213 491.213" style={{ "enableBackground": "new 0 0 491.213 491.213" }} xmlSpace="preserve" fill={color}>
      <g>
        <polygon points="146.213,3.107 146.213,175.607 176.213,175.607 176.213,33.107 461.213,33.107 461.213,458.107 176.213,458.107 
		176.213,315.607 146.213,315.607 146.213,488.107 491.213,488.107 491.213,3.107 	"/>
        <polygon points="318.713,260.607 318.713,230.607 57.427,230.607 91.82,196.213 70.607,175 0,245.607 70.607,316.213 91.82,295 
		57.426,260.607 	"/>
      </g>

    </svg>

  );
}

Door.defaultProps = {
  size: "20px",
  color: 'black'
}
Door.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string
}

export default Door;